import type * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { FormRow } from '../../Form';

export const BagHarvestForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'enrollment.editor.harvests.form' });
  const { register } = useFormContext();

  return (
    <>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='bagHarvest.diameterFeet'>{t('diameterFeet')}</FormLabel>
          <Input
            id='bagHarvest.diameterFeet'
            textAlign='right'
            {...register('bagHarvest.diameterFeet', { valueAsNumber: true, required: true })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='bagHarvest.lengthFeet'>{t('lengthFeet')}</FormLabel>
          <Input
            id='bagHarvest.lengthFeet'
            textAlign='right'
            {...register('bagHarvest.lengthFeet', { valueAsNumber: true, required: true })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormLabel htmlFor='bagHarvest.moistureLevel'>{t('moistureLevel')}</FormLabel>
        <Input
          id='bagHarvest.moistureLevel'
          textAlign='right'
          {...register('bagHarvest.moistureLevel', { valueAsNumber: true })}
        />
      </FormRow>
    </>
  );
};
