import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { parsePhoneNumber } from 'libphonenumber-js';

import { useAuthorizedGraphQLClient } from '../../../graphql/fetcher';
import {
  FindUserByPhoneNumberDocument,
  type FindUserByPhoneNumberQuery,
  type FindUserByPhoneNumberQueryVariables,
} from '../../../graphql/hooks';

export const useValidatePhoneNumberIsAvailable = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.useValidatePhoneNumberIsAvailable' });

  const findUserByPhoneNumberQuery = useAuthorizedGraphQLClient<
    FindUserByPhoneNumberQuery,
    FindUserByPhoneNumberQueryVariables
  >(FindUserByPhoneNumberDocument);
  const { mutateAsync } = useMutation({
    mutationFn: (phoneNumber: string) => findUserByPhoneNumberQuery({ phoneNumber }),
  });

  return useCallback(
    async (value?: string) => {
      if (!value) return true;
      try {
        const phoneNumber = parsePhoneNumber(value, 'CA');
        if (!phoneNumber.isValid()) {
          return false;
        }
        const simplePhoneNumber = phoneNumber.number.replace(/^\+1/, '');
        const { userByPhoneNumber } = await mutateAsync(simplePhoneNumber);
        if (userByPhoneNumber?.farms?.length) {
          return t('unavailable', { farmName: userByPhoneNumber.farms[0].accountName });
        }
        return true;
      } catch {
        return false;
      }
    },
    [mutateAsync, t]
  );
};
