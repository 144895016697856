import { type FieldPath, type FieldValues, useController, type UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormErrorMessage, Input, InputGroup, InputLeftAddon, type InputProps } from '@chakra-ui/react';
import { AsYouType } from 'libphonenumber-js';

export type PhoneNumberInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  isReadOnly?: boolean;
};

function formatAsYouType(value = '') {
  return new AsYouType({ defaultCountry: 'CA' }).input(value);
}

export const PhoneNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  isReadOnly = false,
  ...props
}: PhoneNumberInputProps<TFieldValues, TName>) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.PhoneNumberInput' });
  const {
    field,
    fieldState: { invalid, error },
    // formState: { touchedFields, dirtyFields },
  } = useController(props);
  const onPhoneNumberChange: InputProps['onChange'] = (e) => {
    e.target.value = formatAsYouType(e.target.value);
    return field.onChange(e);
  };

  return (
    <FormControl isInvalid={invalid} isReadOnly={isReadOnly}>
      <InputGroup>
        {!isReadOnly && <InputLeftAddon>+1</InputLeftAddon>}
        <Input
          name={field.name} // send down the input name
          ref={field.ref} // send input ref, so we can focus on input when error appear
          onChange={onPhoneNumberChange} // send value to hook form
          onBlur={field.onBlur} // notify when input is touched/blur
          value={field.value} // input value
          placeholder={t('placeholder')}
        />
      </InputGroup>
      {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};
