import type { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useFirebaseAuth } from '../contexts/FirebaseAuthContext';
import { LookupsProvider } from '../contexts/LookupsContext';
import { UserProvider } from '../contexts/UserContext';

export type LocationProps = {
  state: {
    path: string;
  };
};

const RequireAuth: FC<{ children: JSX.Element }> = ({ children }) => {
  const user = useFirebaseAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to='/login' replace state={{ path: location.pathname }} />;
  }
  return (
    <UserProvider>
      <LookupsProvider>{children}</LookupsProvider>
    </UserProvider>
  );
};

export default RequireAuth;
