import type * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Divider,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import {
  CultureLifecycle,
  type MowingInput,
  MowingStatus,
  useCreateMowingMutation,
  useDeleteMowingMutation,
  useFetchEnrollmentMowingsQuery,
  useUpdateMowingMutation,
} from '../../graphql/hooks';
import { invalidateQueries } from '../../services/cache';
import { FormRow } from '../Form';
import { DatePicker } from '../Form/DatePicker';
import { FormHeader } from '../Form/FormHeader';
import { MowingHarvests } from '../Harvest/MowingHarvests';
import { MowingStatusSelect } from '../Mowing/MowingStatusSelect';

export type EnrollmentMowingsProps = {
  id: number;
  cultureLifecycle: CultureLifecycle;
  onClose: () => void;
  setDirty: (value: boolean) => void;
};

type EnrollmentMowingsFormFields = {
  mowings: (MowingInput & { id?: number })[];
};

export const EnrollmentMowings: React.FC<EnrollmentMowingsProps> = ({ id, cultureLifecycle, onClose, setDirty }) => {
  const { t, i18n } = useTranslation();
  const numberFormat = useMemo(() => new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 0 }), [i18n]);
  const percentFormat = useMemo(
    () => new Intl.NumberFormat(i18n.language, { style: 'percent', minimumFractionDigits: 0 }),
    [i18n]
  );
  const [closeOnSuccess, setCloseOnSuccess] = useState(false);
  const [deletions, setDeletions] = useState<number[]>([]);
  const queryClient = useQueryClient();

  const mowingsQuery = useFetchEnrollmentMowingsQuery(
    {
      id,
      cultureLifecycle,
    },
    {
      enabled: id !== undefined,
    }
  );
  const enrollment = mowingsQuery.data?.enrollmentById;
  const mowings = mowingsQuery.data?.enrollmentById?.mowings;

  const createMowingMutation = useCreateMowingMutation();
  const updateMowingMutation = useUpdateMowingMutation();
  const deleteMowingMutation = useDeleteMowingMutation();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<EnrollmentMowingsFormFields>({ mode: 'all' });
  const { fields, append, remove } = useFieldArray({ name: 'mowings', control });

  const appendMowing = () => {
    append({
      number: fields.length + 1,
      startsAt: new Date().toISOString(),
      endsAt: new Date().toISOString(),
      cultureLifecycle,
      status: MowingStatus.Call,
    });
  };

  const removeMowing = (index: number, number: number) => {
    remove(index);

    const mowing = mowings?.find((mowing) => mowing.number === number);
    if (mowing) {
      setDeletions((deletions) => [...deletions, mowing.id]);
    }
  };

  const initialize = useCallback(() => {
    if (mowings) {
      reset({
        mowings: mowings.map((mowing) => ({
          id: mowing.id,
          number: mowing.number,
          startsAt: mowing.startsAt,
          endsAt: mowing.endsAt,
          cultureLifecycle: mowing.cultureLifecycle,
          status: mowing.status,
        })),
      });
    }
  }, [reset, mowings]);

  useEffect(() => initialize(), [initialize]);

  // Set dirty state on parent
  useEffect(() => setDirty(isDirty), [isDirty, setDirty]);

  // TODO: Error management
  if (mowingsQuery.isLoading || !enrollment || !mowings) {
    return <div />;
  }

  const onSubmit: SubmitHandler<EnrollmentMowingsFormFields> = async (data) => {
    await Promise.all(deletions.map((id) => deleteMowingMutation.mutateAsync({ id })));
    setDeletions([]);

    await Promise.all(
      data.mowings
        .filter((mowing) => mowing.id === undefined)
        .map((mowing) =>
          createMowingMutation.mutateAsync({
            farmId: enrollment.farm.id,
            seasonId: enrollment.season.id,
            data: mowing,
          })
        )
    );

    await Promise.all(
      data.mowings
        .filter((mowing) => mowing.id !== undefined)
        .map((mowing) => {
          const { id, ...mowingData } = mowing;
          return updateMowingMutation.mutateAsync({
            id: id || 0,
            mowingData,
          });
        })
    );

    await invalidateQueries(queryClient);

    if (closeOnSuccess) {
      onClose();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container px={{ base: 0, md: 10 }} mb='170px'>
        <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
          <Stack divider={<StackDivider />} pb={6}>
            <HStack justifyContent='space-between'>
              <FormHeader
                text={t(`enrollment.editor.tabs.${cultureLifecycle.toLowerCase() as 'annual' | 'perennial'}`)}
              />
              <Box pt='6' pr='6'>
                <Button
                  variant='solid'
                  size='xs'
                  leftIcon={<AddIcon />}
                  colorScheme='blue'
                  onClick={() => appendMowing()}
                >
                  {t('enrollment.editor.mowings.add')}
                </Button>
              </Box>
            </HStack>
            {cultureLifecycle === CultureLifecycle.Perennial && (
              <FormRow>
                <Stat>
                  <StatLabel>{t('enrollment.editor.mowings.expected')}</StatLabel>
                  <StatNumber>{enrollment.expectedMowings}</StatNumber>
                </Stat>
              </FormRow>
            )}
          </Stack>
        </Box>
        {fields.map((mowingRow, index) => {
          const mowing = mowings.find((mowing) => mowing.number === mowingRow.number);

          return (
            <Box key={mowingRow.id} bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5} overflowX='auto'>
              <Stack>
                <FormHeader
                  text={t('enrollment.editor.mowings.title', { number: mowingRow.number })}
                  dropdown={
                    cultureLifecycle === CultureLifecycle.Perennial && (
                      <Box w='14rem'>
                        <Controller
                          name={`mowings.${index}.status`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <MowingStatusSelect value={value} onChange={onChange} />
                          )}
                        />
                      </Box>
                    )
                  }
                  icon={
                    <IconButton
                      icon={<DeleteIcon />}
                      variant='unstyled'
                      aria-label={t('enrollment.editor.mowings.delete')}
                      onClick={() => removeMowing(index, mowingRow.number)}
                      isDisabled={!!mowing?.harvests.length}
                    />
                  }
                />
                <Divider />
                <FormRow templateColumns='15rem 15rem 15rem 15rem'>
                  <Stat>
                    <StatLabel>{t('enrollment.editor.mowings.hectares')}</StatLabel>
                    <StatNumber>{numberFormat.format(mowing?.harvestedHectares || 0)}</StatNumber>
                    <StatHelpText>{t('enrollment.editor.mowings.harvestedHectaresUnit')}</StatHelpText>
                  </Stat>
                  <Stat>
                    <StatLabel>{t('enrollment.editor.mowings.yieldPerHectare')}</StatLabel>
                    <StatNumber>{numberFormat.format(mowing?.yieldPerHectare || 0)}</StatNumber>
                    <StatHelpText>{t('enrollment.editor.mowings.yieldUnit')}</StatHelpText>
                  </Stat>
                  {cultureLifecycle === CultureLifecycle.Perennial && (
                    <>
                      <Stat>
                        <StatLabel>{t('enrollment.editor.mowings.publishedYieldAverage')}</StatLabel>
                        <StatNumber>{numberFormat.format(mowing?.publishedYieldAverage || 0)}</StatNumber>
                        <StatHelpText>{t('enrollment.editor.mowings.yieldUnit')}</StatHelpText>
                      </Stat>
                      <Stat>
                        <StatLabel>{t('enrollment.editor.mowings.percentComplete')}</StatLabel>
                        <StatNumber>{percentFormat.format((mowing?.percentComplete || 0) / 100)}</StatNumber>
                      </Stat>
                    </>
                  )}
                </FormRow>
                <>
                  <Divider />
                  <FormRow templateColumns='15rem 15rem 15rem'>
                    <FormControl>
                      <FormLabel htmlFor={`mowings.${index}.startsAt`}>
                        {t('enrollment.editor.mowings.startsAt')}
                      </FormLabel>
                      <Controller
                        name={`mowings.${index}.startsAt`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            selected={new Date(value)}
                            readOnly={mowing?.cultureLifecycle === CultureLifecycle.Annual}
                            onChange={(date) => onChange(date?.toISOString())}
                            popperPlacement='right'
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor={`mowings.${index}.endsAt`}>{t('enrollment.editor.mowings.endsAt')}</FormLabel>
                      <Controller
                        name={`mowings.${index}.endsAt`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            selected={new Date(value)}
                            readOnly={mowing?.cultureLifecycle === CultureLifecycle.Annual}
                            onChange={(date) => onChange(date?.toISOString())}
                            popperPlacement='right'
                          />
                        )}
                      />
                    </FormControl>
                  </FormRow>
                </>
                {mowing && (
                  <MowingHarvests seasonId={enrollment.season.id} farmId={enrollment.farm.id} mowing={mowing} />
                )}
              </Stack>
            </Box>
          );
        })}
      </Container>
      <Fade in={isDirty}>
        <Box bottom='0' position='fixed' left='0' w='100%' py={10} px={14} bg='bg.surface'>
          <Flex>
            <Spacer />
            <HStack spacing={5}>
              <Button variant='tertiary' onClick={initialize}>
                {t('enrollment.editor.actions.cancel')}
              </Button>
              <Button variant='solid' colorScheme='blue' type='submit' isLoading={isSubmitting} isDisabled={!isValid}>
                {t('enrollment.editor.actions.save')}
              </Button>
              <Button
                variant='solid'
                colorScheme='blue'
                type='submit'
                isLoading={isSubmitting}
                isDisabled={!isValid}
                onClick={() => setCloseOnSuccess(true)}
              >
                {t('enrollment.editor.actions.saveAndClose')}
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Fade>
    </form>
  );
};
