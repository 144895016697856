import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from '@chakra-ui/react';

function getVariant(status: EnrollmentStatusBadgeProps['status']) {
  switch (status) {
    case 'TODO':
      return 'enrollmentTodo';
    case 'PENDING':
      return 'enrollmentPending';
    case 'ACCEPTED':
      return 'enrollmentAccepted';
    case 'REFUSED':
      return 'enrollmentRefused';
    case 'UNREACHABLE':
      return 'enrollmentUnreachable';
    case 'COMPLETED':
      return 'enrollmentCompleted';
    case 'WITHDRAWN':
      return 'enrollmentWithdrawn';
    case 'VOLUNTEERED':
      return 'enrollmentVolunteered';
  }
}

export type EnrollmentStatusBadgeProps = {
  status: 'TODO' | 'PENDING' | 'ACCEPTED' | 'REFUSED' | 'UNREACHABLE' | 'COMPLETED' | 'WITHDRAWN' | 'VOLUNTEERED';
};

export const EnrollmentStatusBadge: FC<EnrollmentStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <Tag size='sm' verticalAlign='middle' variant={getVariant(status)}>
      {t(`enrollment.status.enum.${status}` as const)}
    </Tag>
  );
};
