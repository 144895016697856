import type * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { useFetchTowerSilosQuery } from '../../../graphql/hooks';
import { FormDivider, FormRow } from '../../Form';
import { TowerSiloSelect } from '../Widgets/TowerSiloSelect';

export type TowerSiloHarvestFormProps = {
  farmId: number;
  saved: boolean;
};

export const TowerSiloHarvestForm: React.FC<TowerSiloHarvestFormProps> = ({ farmId, saved }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'enrollment.editor.harvests.form' });
  const { control, register, setValue } = useFormContext();
  const query = useFetchTowerSilosQuery({ farmId });

  const setDiameter = (siloNumber: number | null) => {
    const towerSilo = query.data?.farmById?.towerSilos.find((towerSilo) => towerSilo.number === siloNumber);

    if (towerSilo) {
      setValue('towerSiloHarvest.diameterFeet', towerSilo.diameter, { shouldValidate: true, shouldDirty: true });
    }
  };

  return (
    <>
      <FormRow templateColumns='30rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='towerSiloHarvest.siloNumber'>{t('siloNumber')}</FormLabel>
          <Input
            id='towerSiloHarvest.diameterFeet'
            type='hidden'
            {...register('towerSiloHarvest.diameterFeet', { valueAsNumber: true, required: true, min: 1 })}
          />
          <Controller
            name='towerSiloHarvest.siloNumber'
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TowerSiloSelect
                farmId={farmId}
                value={value}
                onChange={(value) => {
                  onChange(value);
                  setDiameter(value);
                }}
                isDisabled={saved}
              />
            )}
          />
        </FormControl>
      </FormRow>
      <FormDivider text={t('towerSilo.height')} />
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='towerSiloHarvest.startingHeight'>{t('startingHeight')}</FormLabel>
          <Input
            id='towerSiloHarvest.startingHeight'
            textAlign='right'
            {...register('towerSiloHarvest.startingHeight', { valueAsNumber: true, required: true })}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='towerSiloHarvest.endingHeight'>{t('endingHeight')}</FormLabel>
          <Input
            id='towerSiloHarvest.endingHeight'
            textAlign='right'
            {...register('towerSiloHarvest.endingHeight', { valueAsNumber: true, required: true })}
          />
        </FormControl>
      </FormRow>
      <FormDivider text={t('towerSilo.feed')} />
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='towerSiloHarvest.dailyFeed'>{t('dailyFeed')}</FormLabel>
          <Input
            id='towerSiloHarvest.dailyFeed'
            textAlign='right'
            {...register('towerSiloHarvest.dailyFeed', { valueAsNumber: true })}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='towerSiloHarvest.days'>{t('days')}</FormLabel>
          <Input
            id='towerSiloHarvest.days'
            textAlign='right'
            {...register('towerSiloHarvest.days', { valueAsNumber: true })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormLabel htmlFor='towerSiloHarvest.moistureLevel'>{t('moistureLevel')}</FormLabel>
        <Input
          id='towerSiloHarvest.moistureLevel'
          textAlign='right'
          {...register('towerSiloHarvest.moistureLevel', { valueAsNumber: true })}
        />
      </FormRow>
    </>
  );
};
