import type * as React from 'react';

import { Spinner, Text } from '@chakra-ui/react';

import { useEnrollmentNotesQuery } from '../../graphql/hooks';

export type EnrollmentLastNoteProps = {
  enrollmentId: number;
};

export const EnrollmentLastNote: React.FC<EnrollmentLastNoteProps> = ({ enrollmentId }) => {
  const enrollmentNotesQuery = useEnrollmentNotesQuery({ enrollmentId });

  if (
    enrollmentNotesQuery.isLoading ||
    enrollmentNotesQuery.isFetching ||
    enrollmentNotesQuery.data === undefined ||
    enrollmentNotesQuery.data.enrollmentById === undefined ||
    enrollmentNotesQuery.data.enrollmentById === null
  ) {
    return <Spinner size='xs' />;
  }
  const { notes } = enrollmentNotesQuery.data.enrollmentById;

  if (notes.length > 0) {
    return <Text whiteSpace='pre-wrap'>{notes[0].content}</Text>;
  }
  return null;
};
