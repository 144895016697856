import * as React from 'react';
import { useState } from 'react';

import { CloseIcon } from '@chakra-ui/icons';
import {
  Container,
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';

import { type MowingFragment, useFetchHarvestQuery } from '../../graphql/hooks';

import { type AnyHarvest, HarvestEditForm } from './HarvestEditForm';

export type HarvestEditModalButtonProps = {
  onClick: () => void;
};

export type HarvestEditModalProps = {
  button: React.ReactElement<HarvestEditModalButtonProps>;
  onUpdate: () => void;
  seasonId: number;
  farmId: number;
  mowing: MowingFragment;
  harvestId?: number;
};

export const HarvestEditModal: React.FC<HarvestEditModalProps> = ({
  seasonId,
  farmId,
  mowing,
  harvestId,
  button,
  onUpdate,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentHarvestId, setCurrentHarvestId] = useState<number | undefined>(harvestId);
  const [updated, setUpdated] = useState<boolean>(false);

  const harvestQuery = useFetchHarvestQuery({ id: currentHarvestId || 0 }, { enabled: !!currentHarvestId && isOpen });
  const harvest = harvestQuery.data?.harvestById;

  const onSave = (id: number) => {
    setCurrentHarvestId(id);
    setUpdated(true);
  };

  const closeOnSave = () => {
    onUpdate();
    onClose();
  };

  const closeModal = () => {
    if (updated) {
      onUpdate();
    }
    onClose();
  };

  return (
    <>
      {React.cloneElement(button, { onClick: onOpen })}
      <Modal closeOnOverlayClick={false} size='full' isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent bg='gray.500' minHeight='100%'>
          <Container px={{ base: 0, md: 10 }} mt={10} maxWidth='6xl'>
            <Flex justify='space-between' mb={5}>
              <Spacer />
              <IconButton
                color='gray.700'
                bg='gray.300'
                aria-label='Close'
                isRound
                onClick={closeModal}
                icon={<CloseIcon />}
              />
            </Flex>
          </Container>
          <HarvestEditForm
            seasonId={seasonId}
            farmId={farmId}
            mowing={mowing}
            harvest={(harvest as AnyHarvest) ?? null}
            onSave={onSave}
            closeOnSave={closeOnSave}
          />
        </ModalContent>
      </Modal>
    </>
  );
};
