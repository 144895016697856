import { theme } from '@chakra-ui/pro-theme';
import { extendTheme, theme as baseTheme } from '@chakra-ui/react';

import { TrendTheme } from './components/Trend';

const proTheme = extendTheme(theme);

export const brandTheme = extendTheme(
  {
    colors: {
      ...proTheme.colors,
      brand: baseTheme.colors.gray,
      cecpaGreen: {
        50: '#F0FCF1',
        100: '#D7F0DB',
        200: '#AFE1B7',
        300: '#87D293',
        400: '#5FC36F',
        500: '#37B44B',
        600: '#2C903C',
        700: '#216C2D',
        800: '#16481E',
        900: '#0B240F',
      },
    },
    styles: {
      global: {
        'html, body': {
          backgroundColor: '#E2E8F0',
        },
      },
    },
    components: {
      Container: {
        baseStyle: {
          maxWidth: '100rem',
        },
      },
      Input: {
        variants: {
          outline: {
            field: {
              _readOnly: {
                bg: 'gray.200',
              },
            },
          },
        },
      },
      Link: {
        baseStyle: {
          color: 'blue.600',
        },
      },
      Tabs: {
        variants: {
          line: {
            tab: {
              borderColor: 'white',
              _selected: {
                color: 'blue.600',
                borderColor: 'blue.600',
              },
            },
          },
        },
      },
      Tag: {
        variants: {
          enrollmentTodo: {
            container: {
              bg: 'yellow.500',
              color: 'white',
            },
          },
          enrollmentRefused: {
            container: {
              bg: 'red.500',
              color: 'white',
            },
          },
          enrollmentAccepted: {
            container: {
              bg: 'teal.500',
              color: 'white',
            },
          },
          enrollmentPending: {
            container: {
              bg: 'purple.500',
              color: 'white',
            },
          },
          enrollmentUnreachable: {
            container: {
              bg: 'gray.400',
              color: 'white',
            },
          },
          enrollmentCompleted: {
            container: {
              bg: 'gray.600',
              color: 'white',
            },
          },
          enrollmentWithdrawn: {
            container: {
              bg: 'gray.400',
              color: 'white',
            },
          },
          enrollmentVolunteered: {
            container: {
              bg: 'blue.500',
              color: 'white',
            },
          },
          participantCall: {
            container: {
              bg: 'orange.100',
              color: 'black',
              borderColor: 'orange.400',
              borderWidth: '1px',
            },
          },
          participantRecall: {
            container: {
              bg: 'blue.200',
              color: 'black',
              borderColor: 'blue.500',
              borderWidth: '1px',
            },
          },
          participantDoNotCall: {
            container: {
              bg: 'red.300',
              color: 'black',
              borderColor: 'red.600',
              borderWidth: '1px',
            },
          },
          participantCompleted: {
            container: {
              bg: 'green.200',
              color: 'black',
              borderColor: 'green.600',
              borderWidth: '1px',
            },
          },
        },
      },
      Trend: TrendTheme,
    },
  },
  proTheme
);
