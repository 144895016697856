import type * as React from 'react';
import { useMemo } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  HStack,
  Spacer,
  Stack,
  StackDivider,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { useUser } from '../../contexts/UserContext';
import { useCreateEnrollmentNoteMutation, useEnrollmentNotesQuery } from '../../graphql/hooks';
import { invalidateQueries } from '../../services/cache';
import { FormHeader } from '../Form';

export type NotePageProps = {
  id: string | undefined;
};

type NoteFormFields = {
  content: string;
};

export const NotePage: React.FC<NotePageProps> = ({ id }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const user = useUser();

  const dateFormat = useMemo(() => new Intl.DateTimeFormat('fr-CA'), []);
  const timeFormat = useMemo(() => new Intl.DateTimeFormat('fr-CA', { timeStyle: 'short' }), []);

  const enrollmentId = Number(id || '');
  const notesQuery = useEnrollmentNotesQuery({ enrollmentId }, { enabled: id !== undefined });
  const createNoteMutation = useCreateEnrollmentNoteMutation();

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<NoteFormFields>({ mode: 'all' });
  useFormPersist(`notes-${id}`, {
    watch,
    setValue,
    storage: window.localStorage,
    validate: true,
    dirty: true,
  });

  const onSubmit: SubmitHandler<NoteFormFields> = async (data) => {
    await createNoteMutation.mutateAsync({ enrollmentId, ...data });
    await invalidateQueries(queryClient);
    reset({ content: '' });
  };

  const enrollment = notesQuery.data?.enrollmentById;
  const notes = enrollment?.notes;

  return (
    <Container px={{ base: 0, md: 3 }}>
      <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
        <Stack divider={<StackDivider />}>
          <FormHeader
            spacing={2}
            align='baseline'
            text={dateFormat.format(new Date())}
            dropdown={
              <Text fontSize='sm'>
                {timeFormat.format(new Date())} - {user?.email}
              </Text>
            }
          />
          <Box px={5} pt={1} pb={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <Textarea
                  rows={3}
                  id='contents'
                  placeholder={t('enrollment.editor.notes.placeholder')}
                  {...register('content', { required: true })}
                />
                <FormHelperText>{t('enrollment.editor.help.notes')}</FormHelperText>
              </FormControl>
              <Flex pt={5}>
                <Spacer />
                <Button variant='solid' size='xs' colorScheme='blue' type='submit' isDisabled={!isValid}>
                  {t('enrollment.editor.notes.add')}
                </Button>
              </Flex>
            </form>
          </Box>
        </Stack>
      </Box>
      {notes && notes.length > 0 && (
        <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
          <Stack divider={<StackDivider />} pb={6} pt={6}>
            {notes.map((note) => (
              <VStack key={note.id} spacing={5} align='stretch' pl={6} pr={6}>
                <Box>
                  <HStack spacing={2} align='baseline'>
                    <Text fontWeight='bold'>{dateFormat.format(new Date(note.createdAt))}</Text>
                    <Text fontSize='sm'>
                      {timeFormat.format(new Date(note.createdAt))} - {note.author.email}
                    </Text>
                  </HStack>
                  <Text fontSize='sm' pt={3} pb={4} whiteSpace='pre-wrap'>
                    {note.content}
                  </Text>
                </Box>
              </VStack>
            ))}
          </Stack>
        </Box>
      )}
    </Container>
  );
};
