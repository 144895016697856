import type * as React from 'react';

import { Divider, HStack, Text } from '@chakra-ui/react';

export type FormDividerProps = {
  text: string;
};

export const FormDivider: React.FC<FormDividerProps> = ({ text }) => (
  <HStack px='6' pt='8' whiteSpace='nowrap'>
    <Divider />
    <Text fontSize='sm' fontWeight='bold' color='gray.400' textTransform='uppercase'>
      {text}
    </Text>
    <Divider />
  </HStack>
);
