import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'chakra-react-select';

import { RotoCut } from '../../../graphql/hooks';

export type RotoCutSelectProps = {
  onChange: (value: RotoCut | null) => void;
  value: RotoCut | null | undefined;
  isClearable?: boolean;
};

const typedKeys = <T extends Record<string, unknown>>(object: T): (keyof T)[] => Object.keys(object) as (keyof T)[];

export const RotoCutSelect: React.FC<RotoCutSelectProps> = ({ onChange, value, isClearable }) => {
  const { t } = useTranslation();
  const options = typedKeys(RotoCut)
    .map((rotocut) => ({
      value: RotoCut[rotocut],
      label: t(`enrollment.editor.rotocutTypes.${RotoCut[rotocut]}`),
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));

  const selectValue = options.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      isClearable={isClearable}
      value={selectValue ?? null}
      onChange={(e) => onChange(e?.value ?? null)}
      options={options}
      placeholder={t('components.select.placeholder')}
    />
  );
};
