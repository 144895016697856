import { type FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { type Column, type Row, usePagination, useSortBy, useTable } from 'react-table';

import { ArrowDownIcon, ArrowUpIcon, ChatIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  HStack,
  Progress,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { keepPreviousData } from '@tanstack/react-query';
import { useDidUpdate, useSessionstorageState } from 'rooks';

import { useUser } from '../../contexts/UserContext';
import {
  type EnrollmentListFieldsFragment,
  EnrollmentSortField,
  type EnrollmentSortInput,
  type EnrollmentStatus,
  SortOrder,
  useEnrollmentsForListQuery,
} from '../../graphql/hooks';

import { EnrollmentLastNote } from './EnrollmentLastNote';
import { EnrollmentStatusBadge } from './EnrollmentStatusBadge';

type EnrollmentListProps = {
  season: number | null;
  station: number | null;
  status: EnrollmentStatus | null;
  search: string | null;
};

export const EnrollmentTable: FC<EnrollmentListProps> = ({ season, station, status, search }) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'enrollment.table' });
  const navigate = useNavigate();
  const location = useLocation();
  const dateTimeFormat = useMemo(() => new Intl.DateTimeFormat(i18n.language), [i18n.language]);
  const user = useUser();

  const [selectedRowId, setSelectedRowId] = useSessionstorageState<number>('enrollments.selectedRowId', -1);
  const [querySort, setQuerySort] = useSessionstorageState<EnrollmentSortInput[]>('enrollments.sort', [
    { field: EnrollmentSortField.Station, order: SortOrder.Asc },
    { field: EnrollmentSortField.SelectionOrder, order: SortOrder.Asc },
  ]);
  const [queryPageIndex, setQueryPageIndex] = useSessionstorageState('enrollments.page', 0);
  const pageSize = 50;

  const enrollmentQuery = useEnrollmentsForListQuery(
    {
      seasonId: season || 0,
      sponsorId: user?.sponsor?.id || '',
      stationId: station,
      status,
      search,
      pageIndex: queryPageIndex,
      sort: querySort,
    },
    {
      enabled: season !== null,
      staleTime: 5 * 60 * 1000,
      placeholderData: keepPreviousData,
    }
  );
  const enrollments = useMemo(() => enrollmentQuery.data?.enrollments || [], [enrollmentQuery]);
  const enrollmentsCount = useMemo(() => enrollmentQuery.data?.enrollmentCount || 0, [enrollmentQuery]);

  const defaultColumn: Partial<Column<EnrollmentListFieldsFragment>> = useMemo(
    () => ({
      width: '0.1%',
    }),
    []
  );

  const columns: Array<Column<EnrollmentListFieldsFragment>> = useMemo(
    () => [
      {
        id: EnrollmentSortField.SelectionOrder,
        accessor: 'selectionOrder',
        Header: t('headers.selectionOrder'),
      },
      {
        id: EnrollmentSortField.Status,
        accessor: 'status',
        Header: t('headers.status'),
        Cell: ({ value }: { value: EnrollmentStatus }) => <EnrollmentStatusBadge status={value} />,
        minWidth: 160,
      },
      {
        id: EnrollmentSortField.Number,
        accessor: (enrollment) => enrollment.farm.number || '',
        Header: t('headers.farmNumber'),
      },
      {
        id: EnrollmentSortField.Station,
        accessor: (enrollment) =>
          enrollment.farm.station.id > 0
            ? `${enrollment.farm.station.number} - ${enrollment.farm.station.name}`
            : enrollment.farm.station.name,
        Header: t('headers.farmStation'),
      },
      {
        id: EnrollmentSortField.Name,
        accessor: (enrollment) =>
          `${enrollment.farm.accountName} || ${enrollment.farm.customerFirstName || ''} ${
            enrollment.farm.customerLastName || ''
          }`.trim(),
        Header: t('headers.farmName'),
        width: '100%',
        Cell: ({ value }: { value: string }) => {
          const [account, customer] = value.split('||');
          return (
            <VStack align='start' spacing='0'>
              <Text>{account.trim()}</Text>
              <Text color='gray.500'>{customer.trim()}&nbsp;</Text>
            </VStack>
          );
        },
      },
      {
        id: EnrollmentSortField.UpdatedAt,
        accessor: 'updatedAt',
        Header: t('headers.updatedAt'),
        Cell: ({ value }: { value: string }) => <span>{dateTimeFormat.format(new Date(value))}</span>,
        sortDescFirst: true,
      },
      {
        id: 'note',
        accessor: 'noteCount',
        Header: t('headers.note'),
        Cell: (cell) =>
          cell.value > 0 && (
            <Tooltip label={<EnrollmentLastNote enrollmentId={cell.row.original.id} />} placement='bottom-end'>
              <ChatIcon />
            </Tooltip>
          ),
        disableSortBy: true,
      },
    ],
    [t, dateTimeFormat]
  ) /* FIXME: Type Hack! */ as Array<Column<EnrollmentListFieldsFragment>>;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data: enrollments,
      defaultColumn,
      autoResetSortBy: false,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: Math.ceil(enrollmentsCount / pageSize),
      initialState: {
        sortBy: querySort.map(({ field, order }) => ({
          id: field,
          desc: order === SortOrder.Desc,
        })),
        pageIndex: queryPageIndex,
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => setQueryPageIndex(pageIndex), [pageIndex, setQueryPageIndex]);

  useEffect(() => {
    setQuerySort(
      sortBy.map(({ id, desc }) => ({
        field: id as EnrollmentSortField,
        order: desc ? SortOrder.Desc : SortOrder.Asc,
      }))
    );
  }, [setQuerySort, sortBy]);

  useDidUpdate(() => gotoPage(0), [gotoPage, season, station, status, search, sortBy]);

  const handleRowClick = (row: Row<EnrollmentListFieldsFragment>) => {
    setSelectedRowId(row.original.id);
    navigate(`/enrollment/edit/${row.original.id}`, {
      state: { previousLocation: location },
    });
  };

  return (
    <Box>
      {enrollmentQuery.isFetching ? <Progress size='xs' colorScheme='green' isIndeterminate /> : <Spacer height={1} />}
      <Stack spacing='5'>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ style: { width: column.width, userSelect: 'none' } })
                    )}
                  >
                    {column.render('Header')}
                    <chakra.span pl='4'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownIcon aria-label='sorted descending' />
                        ) : (
                          <ArrowUpIcon aria-label='sorted ascending' />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  verticalAlign='top'
                  {...(selectedRowId === row.original.id && { bg: 'cecpaGreen.50' })}
                  _hover={{ background: 'gray.200' }}
                  cursor='pointer'
                  onClick={() => handleRowClick(row)}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps({ style: { width: cell.column.width, minWidth: cell.column.minWidth } })}>
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Box px={{ base: '4', md: '6' }} pb='5'>
          <HStack spacing='3' justify='space-between'>
            {!enrollmentQuery.isLoading && (
              <>
                <Text color='fg.muted' fontSize='sm'>
                  {t('pagination.results', {
                    lower: Math.min(pageSize * pageIndex + 1, enrollmentsCount),
                    upper: Math.min(pageSize * (pageIndex + 1), enrollmentsCount),
                    total: enrollmentsCount,
                  })}
                </Text>
                <ButtonGroup
                  spacing='3'
                  justifyContent='space-between'
                  width={{ base: 'full', md: 'auto' }}
                  variant='secondary'
                >
                  <Button isDisabled={!canPreviousPage} onClick={() => previousPage()}>
                    {t('pagination.previous')}
                  </Button>
                  <Button isDisabled={!canNextPage} onClick={() => nextPage()}>
                    {t('pagination.next')}
                  </Button>
                </ButtonGroup>
              </>
            )}
          </HStack>
        </Box>
      </Stack>
    </Box>
  );
};
