import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { type OptionBase, Select } from 'chakra-react-select';

import { useLookups } from '../../contexts/LookupsContext';

export type SeasonSelectProps = {
  onSelectSeason: (seasonId: number | null) => void;
  value: number | null;
};

interface SeasonOption extends OptionBase {
  readonly value: number;
  readonly label: string;
}

export const SeasonSelect: React.FC<SeasonSelectProps> = ({ onSelectSeason, value }) => {
  const { t } = useTranslation();
  const { query, seasons } = useLookups();

  const options: readonly SeasonOption[] = (seasons.data || []).map((season) => ({
    value: season.id,
    label: season.name,
  }));
  const selectValue = options.find((option) => option.value === value);

  return query.isLoading ? (
    <span>{t('loading')}</span>
  ) : query.isError ? (
    <span>{t('error')}</span>
  ) : (
    <Select
      isSearchable={false}
      value={selectValue}
      onChange={(e) => onSelectSeason(e?.value ?? null)}
      options={options}
      placeholder={t('components.select.placeholder')}
    />
  );
};
