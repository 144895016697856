import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { type OptionBase, Select } from 'chakra-react-select';

import { useFetchTowerSilosQuery } from '../../../graphql/hooks';

export type TowerSiloSelectProps = {
  farmId: number;
  onChange: (value: number | null) => void;
  value: number | null | undefined;
  isClearable?: boolean;
  isDisabled?: boolean;
};

interface TowerSiloOption extends OptionBase {
  readonly value: number;
  readonly label: string;
}

export const TowerSiloSelect: React.FC<TowerSiloSelectProps> = ({
  farmId,
  onChange,
  value,
  isClearable,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const query = useFetchTowerSilosQuery({ farmId });

  const options: readonly TowerSiloOption[] = (query.data?.farmById?.towerSilos || [])
    .filter((towerSilo) => towerSilo.isActive || towerSilo.number === value)
    .map((towerSilo) => ({
      value: towerSilo.number,
      label: t('components.TowerSiloSelect.label', {
        number: towerSilo.number,
        diameter: towerSilo.diameter,
        height: towerSilo.height,
      }),
    }));

  const selectValue = options.find((option) => option.value === value);

  return query.isLoading ? (
    <span>{t('loading')}</span>
  ) : query.isError ? (
    <span>{t('error')}</span>
  ) : (
    <Select
      isSearchable={false}
      isClearable={isClearable}
      value={selectValue ?? null}
      onChange={(e) => onChange(e?.value ?? null)}
      options={options}
      placeholder={t('components.select.placeholder')}
      isDisabled={isDisabled}
    />
  );
};
