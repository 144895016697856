import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'chakra-react-select';

export type BooleanSelectProps = {
  onChange: (value: boolean | null) => void;
  placeholder?: string;
  isClearable?: boolean;
  value: boolean | null;
};

export const BooleanSelect: React.FC<BooleanSelectProps> = ({ onChange, value, placeholder, isClearable }) => {
  const { t } = useTranslation();
  const options = [
    { value: true, label: t('components.BooleanSelect.options.true') },
    { value: false, label: t('components.BooleanSelect.options.false') },
  ];

  const selectValue = options.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      isClearable={isClearable}
      value={selectValue}
      onChange={(e) => onChange(e?.value ?? null)}
      options={options}
      placeholder={placeholder || t('components.select.placeholder')}
    />
  );
};
