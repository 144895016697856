import type * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Stack,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useLocalstorageState, useSessionstorageState } from 'rooks';
import { useDebounce } from 'use-debounce';

import { useLookups } from '../../contexts/LookupsContext';
import type { MowingStatus, RegionType } from '../../graphql/hooks';
import { BooleanSelect } from '../BooleanSelect';
import { MowingStatusSelect } from '../Mowing/MowingStatusSelect';
import { MowingNumberSelect } from '../MowingNumberSelect';
import { RegionTypeSelect } from '../RegionTypeSelect';
import { SeasonSelect } from '../Season';

import { ParticipantTable } from './ParticipantTable';

export const ParticipantListPage: React.FC = () => {
  const { t } = useTranslation();
  const { seasons } = useLookups();

  const [selectedSeason, setSelectedSeason] = useSessionstorageState<number | null>(
    'participants.selectedSeason',
    null
  );

  const [searchText, setSearchText] = useSessionstorageState<string>('participants.searchText', '');
  const [search] = useDebounce(searchText, 500);

  const [selectedMowingNumber, setSelectedMowingNumber] = useLocalstorageState<number>('participants.mowingNumber', 1);

  const [selectedRegionType, setSelectedRegionType] = useSessionstorageState<RegionType | null>(
    'participants.regionType',
    null
  );

  const [selectedAppFilter, setSelectedAppFilter] = useSessionstorageState<boolean | null>(
    'participants.appFilter',
    null
  );

  const [selectedMowingStatus, setSelectedMowingStatus] = useSessionstorageState<MowingStatus | null>(
    'participants.mowingStatus',
    null
  );

  const [expectedMowing, setExpectedMowing] = useSessionstorageState<boolean>('participants.expectedMowing', false);

  useEffect(() => {
    if (selectedSeason === null && seasons.current) {
      setSelectedSeason(seasons.current.id);
    }
  }, [setSelectedSeason, selectedSeason, seasons]);

  return (
    <Container px={{ base: 0, md: 3 }}>
      <Box
        bg='bg.surface'
        boxShadow={{ base: 'none', md: useColorModeValue('sm', 'sm-dark') }}
        borderRadius={useBreakpointValue({ base: 'none', md: 'lg' })}
        mb={{ base: 3, md: 16 }}
      >
        <Stack spacing={5}>
          <Box px={{ base: 4, md: 6 }} pt={5}>
            <SimpleGrid
              pb={5}
              spacing={{ sm: 5, xl: 10 }}
              templateColumns={{ sm: '1fr', md: '1fr 1fr', xl: '1fr 35rem' }}
            >
              <div />
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <SearchIcon color='gray.300' />
                </InputLeftElement>
                <Input onChange={(e) => setSearchText(e.target.value)} value={searchText} />
              </InputGroup>
            </SimpleGrid>
            <SimpleGrid
              spacing={{ sm: 5, '2xl': 10 }}
              templateColumns={{ sm: '1fr', md: '1fr 1fr', '2xl': '12rem 15rem 15rem 11rem 16rem 1fr' }}
            >
              <SeasonSelect value={selectedSeason} onSelectSeason={setSelectedSeason} />
              <MowingNumberSelect value={selectedMowingNumber} onChange={setSelectedMowingNumber} />
              <RegionTypeSelect value={selectedRegionType} onChange={setSelectedRegionType} />
              <BooleanSelect
                isClearable
                value={selectedAppFilter}
                onChange={setSelectedAppFilter}
                placeholder={t('components.AppFilterSelect.placeholder')}
              />
              <MowingStatusSelect value={selectedMowingStatus} onChange={setSelectedMowingStatus} isClearable />
              <Checkbox isChecked={expectedMowing} onChange={(e) => setExpectedMowing(e.target.checked)}>
                <Tooltip
                  label={t('participant.list.filters.expectedMowingHelp', { selectedMowingNumber })}
                  placement='bottom'
                  offset={[0, 20]}
                >
                  {t('participant.list.filters.expectedMowing')}
                </Tooltip>
              </Checkbox>
            </SimpleGrid>
          </Box>
          <Box overflowX='auto'>
            <ParticipantTable
              season={selectedSeason}
              mowingNumber={selectedMowingNumber}
              regionType={selectedRegionType}
              expectedMowing={expectedMowing}
              hasApp={selectedAppFilter}
              mowingStatus={selectedMowingStatus}
              search={search}
            />
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
