import type * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { FormRow } from '../../Form';

export const BunkerSiloHarvestForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'enrollment.editor.harvests.form' });
  const { register } = useFormContext();

  return (
    <>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='bunkerSiloHarvest.widthFeet'>{t('widthFeet')}</FormLabel>
          <Input
            id='bunkerSiloHarvest.widthFeet'
            textAlign='right'
            {...register('bunkerSiloHarvest.widthFeet', {
              valueAsNumber: true,
              required: true,
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='bunkerSiloHarvest.lengthFeet'>{t('lengthFeet')}</FormLabel>
          <Input
            id='bunkerSiloHarvest.lengthFeet'
            textAlign='right'
            {...register('bunkerSiloHarvest.lengthFeet', {
              valueAsNumber: true,
              required: true,
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='bunkerSiloHarvest.heightFeet'>{t('heightFeet')}</FormLabel>
          <Input
            id='bunkerSiloHarvest.heightFeet'
            textAlign='right'
            {...register('bunkerSiloHarvest.heightFeet', {
              valueAsNumber: true,
              required: true,
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormLabel htmlFor='bunkerSiloHarvest.moistureLevel'>{t('moistureLevel')}</FormLabel>
        <Input
          id='bunkerSiloHarvest.moistureLevel'
          textAlign='right'
          {...register('bunkerSiloHarvest.moistureLevel', { valueAsNumber: true })}
        />
      </FormRow>
    </>
  );
};
