import type { QueryClient } from '@tanstack/react-query';

export const invalidateQueries = async (queryClient: QueryClient) => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: ['enrollmentsForList'] }),
    queryClient.invalidateQueries({ queryKey: ['participantsForList'] }),
    queryClient.invalidateQueries({ queryKey: ['stationsWithTarget'] }),
    queryClient.invalidateQueries({ queryKey: ['enrollmentForEditor'] }),
    queryClient.invalidateQueries({ queryKey: ['FetchEnrollmentMowings'] }),
    queryClient.invalidateQueries({ queryKey: ['enrollmentNotes'] }),
  ]);
};
