import 'firebase/compat/auth';

import { EmailAuthProvider, getAuth, GoogleAuthProvider } from 'firebase/auth';

import { StyledFirebaseAuth } from './Auth';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [EmailAuthProvider.PROVIDER_ID, GoogleAuthProvider.PROVIDER_ID],
  signInSuccessUrl: '/',
};

const Login = () => <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />;

export default Login;
