import { createContext, type FC, type ReactNode, useContext } from 'react';

import type { UseQueryResult } from '@tanstack/react-query';

import {
  type CityFieldsFragment,
  type LookupsQuery,
  type RegionFieldsFragment,
  type SeasonFieldsFragment,
  type ServiceCenterFieldsFragment,
  type SponsorFieldsFragment,
  type StationFieldsFragment,
  useLookupsQuery,
} from '../graphql/hooks';

type Context = {
  query: UseQueryResult<LookupsQuery, unknown>;
  cities: {
    data: CityFieldsFragment[];
  };
  regions: {
    data: RegionFieldsFragment[];
  };
  seasons: {
    current?: SeasonFieldsFragment;
    data: SeasonFieldsFragment[];
  };
  serviceCenters: {
    data: ServiceCenterFieldsFragment[];
  };
  sponsors: {
    data: SponsorFieldsFragment[];
  };
  stations: {
    data: StationFieldsFragment[];
  };
};

const LookupsContext = createContext<Context | undefined>(undefined);

const LookupsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const lookupsQuery = useLookupsQuery(
    {},
    {
      refetchOnWindowFocus: false,
      staleTime: 24 * 3600 * 1000,
    }
  );

  const seasons = lookupsQuery.data?.seasons || [];

  const value: Context = {
    query: lookupsQuery,
    cities: {
      data: lookupsQuery.data?.cities || [],
    },
    regions: {
      data: lookupsQuery.data?.regions || [],
    },
    seasons: {
      current: seasons.find((season) => season.current),
      data: seasons,
    },
    serviceCenters: {
      data: lookupsQuery.data?.serviceCenters || [],
    },
    sponsors: {
      data: lookupsQuery.data?.sponsors || [],
    },
    stations: {
      data: lookupsQuery.data?.stations || [],
    },
  };

  return <LookupsContext.Provider value={value}>{children}</LookupsContext.Provider>;
};

function useLookups() {
  const lookups = useContext(LookupsContext);
  if (lookups === undefined) {
    throw new Error('useLookups must be used within a LookupsProvider');
  }
  return lookups;
}
export { LookupsProvider, useLookups };
