import type * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { type MowingFragment, useDeleteHarvestMutation, useFetchMowingHarvestsQuery } from '../../graphql/hooks';
import { invalidateQueries } from '../../services/cache';

import { ConfirmationDialog } from './Widgets/ConfirmationDialog';
import { HarvestEditModal } from './HarvestEditModal';

export type MowingHarvestsProps = {
  mowing: MowingFragment;
  seasonId: number;
  farmId: number;
};

export const MowingHarvests: React.FC<MowingHarvestsProps> = ({ mowing, seasonId, farmId }) => {
  const { t, i18n } = useTranslation();
  const dateTimeFormat = useMemo(() => new Intl.DateTimeFormat(i18n.language), [i18n.language]);
  const numberFormat = useMemo(() => new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 2 }), [i18n]);

  const queryClient = useQueryClient();
  const harvestsQueryKey = {
    mowingId: mowing.id,
  };
  const harvestsQuery = useFetchMowingHarvestsQuery(harvestsQueryKey);
  const harvests = harvestsQuery.data?.mowingById.harvests;

  const invalidate = () => invalidateQueries(queryClient);

  const deleteHarvestMutation = useDeleteHarvestMutation({ onSuccess: invalidate });
  const deleteHarvest = (id: number) => deleteHarvestMutation.mutate({ id });

  // TODO: Error management
  if (harvestsQuery.isLoading || !mowing) {
    return null;
  }

  return (
    <>
      {!!harvests?.length && (
        <Table>
          <Thead>
            <Tr>
              <Th width='100%'>{t('enrollment.editor.harvests.harvestType')}</Th>
              <Th width='1%'>{t('enrollment.editor.harvests.date')}</Th>
              <Th width='1%'>{t('enrollment.editor.harvests.culture')}</Th>
              <Th width='1%'>{t('enrollment.editor.harvests.baleWeight')}</Th>
              <Th width='1%'>{t('enrollment.editor.harvests.calculatedYield')}</Th>
              <Th width='1%'>{t('enrollment.editor.harvests.hectares')}</Th>
              <Th width='1%'>{t('enrollment.editor.harvests.yieldPerHectare')}</Th>
              <Th width='1%'>{t('enrollment.editor.harvests.updatedAt')}</Th>
              <Th width='1%' />
            </Tr>
          </Thead>
          <Tbody>
            {harvests?.map((harvest) => (
              <Tr key={harvest.id}>
                <Td>
                  <Text>{t(`harvestTypes.${harvest.harvestType}`)}</Text>
                </Td>
                <Td>
                  {harvest.date !== null && harvest.date !== undefined && (
                    <Text textAlign='right'>{dateTimeFormat.format(new Date(harvest.date))}</Text>
                  )}
                </Td>
                <Td>{harvest.culture?.name}</Td>
                <Td>
                  <Text textAlign='right'>{numberFormat.format(harvest.baleWeight || 0)}</Text>
                </Td>
                <Td>
                  <Text textAlign='right'>{numberFormat.format(harvest.calculatedYield || 0)}</Text>
                </Td>
                <Td>
                  <Text textAlign='right'>{numberFormat.format(harvest.hectares || 0)}</Text>
                </Td>
                <Td>
                  <Text textAlign='right'>{numberFormat.format(harvest.yieldPerHectare || 0)}</Text>
                </Td>
                <Td>
                  <Text>{dateTimeFormat.format(new Date(harvest.updatedAt))}</Text>
                </Td>
                <Td>
                  <ConfirmationDialog
                    title={t('enrollment.editor.harvests.deletion.title')}
                    text={t('enrollment.editor.harvests.deletion.text')}
                    confirm={t('enrollment.editor.harvests.deletion.confirm')}
                    onConfirm={() => deleteHarvest(harvest.id)}
                    button={
                      <IconButton
                        aria-label={t('enrollment.editor.harvests.deletion.button')}
                        variant='unstyled'
                        icon={<DeleteIcon />}
                      />
                    }
                  />
                  <HarvestEditModal
                    seasonId={seasonId}
                    farmId={farmId}
                    mowing={mowing}
                    harvestId={harvest.id}
                    onUpdate={invalidate}
                    button={
                      <Button variant='solid' size='xs' colorScheme='blue'>
                        {t('enrollment.editor.harvests.open')}
                      </Button>
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      <HStack p='1.5rem' pb='2rem'>
        <HarvestEditModal
          seasonId={seasonId}
          farmId={farmId}
          mowing={mowing}
          onUpdate={invalidate}
          button={
            <Button variant='solid' size='xs' leftIcon={<AddIcon />} colorScheme='blue'>
              {t('enrollment.editor.harvests.add')}
            </Button>
          }
        />
      </HStack>
    </>
  );
};
