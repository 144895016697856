import type * as React from 'react';

import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { HStack, Text, useStyleConfig } from '@chakra-ui/react';

type TrendProps = {
  value: number;
};

export const TrendTheme = {
  baseStyle: {
    fontWeight: 700,
  },
  variants: {
    positive: {
      color: 'cecpaGreen.500',
    },
    negative: {
      color: 'orange.600',
    },
  },
};

export const Trend: React.FC<TrendProps> = ({ value }) => {
  const rising = value > 0;
  const trendText = Math.abs(Math.round(value * 1000) / 10);
  const styles = useStyleConfig('Trend', { variant: rising ? 'positive' : 'negative' });
  return (
    <HStack sx={styles}>
      {rising ? <ArrowUpIcon /> : <ArrowDownIcon />}
      <Text>{trendText} %</Text>
    </HStack>
  );
};
