import { createContext, type FC, type ReactNode, useContext, useEffect, useState } from 'react';

import { fetchAndActivate, getBoolean, getRemoteConfig } from 'firebase/remote-config';

type RemoteConfigValues = {
  enrollmentEditorWithTabs: boolean;
};

const defaults: RemoteConfigValues = {
  enrollmentEditorWithTabs: false,
};

const FirebaseRemoteConfigContext = createContext<RemoteConfigValues>(defaults);

const FirebaseRemoteConfigProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [values, setValues] = useState<RemoteConfigValues>(defaults);

  useEffect(() => {
    const remoteConfig = getRemoteConfig();

    remoteConfig.settings.minimumFetchIntervalMillis = 60 * 60 * 1000;
    remoteConfig.defaultConfig = defaults;

    fetchAndActivate(remoteConfig).then(() => {
      setValues({
        enrollmentEditorWithTabs: getBoolean(remoteConfig, 'enrollmentEditorWithTabs'),
      });
    });
  }, []);

  return <FirebaseRemoteConfigContext.Provider value={values}>{children}</FirebaseRemoteConfigContext.Provider>;
};

function useRemoteConfig() {
  return useContext(FirebaseRemoteConfigContext);
}

export { FirebaseRemoteConfigProvider, useRemoteConfig };
