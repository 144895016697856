import type * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';

import { Avatar, Box, Button, ButtonGroup, Container, Flex, HStack } from '@chakra-ui/react';

import { useFirebaseAuth } from '../contexts/FirebaseAuthContext';

import { Logo } from './Logo';

type ButtonNavLinkProps = {
  children: React.ReactNode;
  to: string;
};

const ButtonNavLink: React.FC<ButtonNavLinkProps> = ({ to, children }) => {
  const path = useResolvedPath(to);
  const isActive = useMatch(path.pathname);
  const ariaCurrent = isActive ? 'page' : undefined;
  const navigate = useNavigate();
  return (
    <Button aria-current={ariaCurrent} onClick={() => navigate(to)}>
      {children}
    </Button>
  );
};

export const Nav = () => {
  const { t } = useTranslation();
  const user = useFirebaseAuth();
  return (
    <Box as='section' pb={{ base: 3, md: 16 }}>
      <Box as='nav' bg='bg.accent.default' color='fg.accent.default'>
        <Container py='2'>
          <Flex justify='space-between'>
            <HStack spacing='4'>
              <Logo />
              {user && (
                <ButtonGroup variant='tertiary.accent' spacing='3' size='sm'>
                  <ButtonNavLink to='/enrollment'>{t('nav.enrollment')}</ButtonNavLink>
                </ButtonGroup>
              )}
              {user && (
                <ButtonGroup variant='tertiary.accent' spacing='3' size='sm'>
                  <ButtonNavLink to='/participants'>{t('nav.participants')}</ButtonNavLink>
                </ButtonGroup>
              )}
              {user && (
                <ButtonGroup variant='tertiary.accent' spacing='3' size='sm'>
                  <ButtonNavLink to='/yieldAverage'>{t('nav.yieldAverage')}</ButtonNavLink>
                </ButtonGroup>
              )}
              {user && (
                <ButtonGroup variant='tertiary.accent' spacing='3' size='sm'>
                  <ButtonNavLink to='/reports'>{t('nav.reports')}</ButtonNavLink>
                </ButtonGroup>
              )}
            </HStack>
            <HStack spacing='4'>
              <Avatar boxSize='10' name={user?.displayName || ''} src={user?.photoURL || ''} />
            </HStack>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
