import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'chakra-react-select';

import { EnrollmentStatus } from '../../graphql/hooks';

export type EnrollmentStatusSelectProps = {
  onChange: (value: EnrollmentStatus | null) => void;
  value: EnrollmentStatus | null | undefined;
  isClearable?: boolean;
};

const typedKeys = <T extends Record<string, unknown>>(object: T): (keyof T)[] => Object.keys(object) as (keyof T)[];

export const EnrollmentStatusSelect: React.FC<EnrollmentStatusSelectProps> = ({ onChange, value, isClearable }) => {
  const { t } = useTranslation();
  const options = typedKeys(EnrollmentStatus).map((status) => ({
    value: EnrollmentStatus[status],
    label: t(`enrollment.status.enum.${EnrollmentStatus[status]}`),
  }));

  const selectValue = options.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      isClearable={isClearable}
      value={selectValue}
      onChange={(e) => onChange(e?.value ?? null)}
      options={options}
      placeholder={t('enrollment.status.select.placeholder')}
    />
  );
};
