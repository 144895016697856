import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

export type ConfirmationDialogButtonProps = {
  onClick: () => void;
};

export type ConfirmationDialogProps = {
  button: React.ReactElement<ConfirmationDialogButtonProps>;
  title: string;
  text: string;
  onConfirm: () => void;
  confirm?: string;
  cancel?: string;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  button,
  title,
  text,
  confirm,
  cancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const confirmAction = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      {React.cloneElement(button, { onClick: onOpen })}
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{text}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {cancel ?? t('components.ConfirmationDialog.cancel')}
            </Button>
            <Button colorScheme='red' ml={3} onClick={confirmAction}>
              {confirm ?? t('components.ConfirmationDialog.confirm')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
