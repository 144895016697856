import type * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { FormRow } from '../../Form';

export const LoadHarvestForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'enrollment.editor.harvests.form' });
  const { register } = useFormContext();

  return (
    <>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='loadHarvest.loads'>{t('loads')}</FormLabel>
          <Input
            id='loadHarvest.loads'
            textAlign='right'
            {...register('loadHarvest.loads', { valueAsNumber: true, required: true })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='loadHarvest.widthFeet'>{t('widthFeet')}</FormLabel>
          <Input
            id='loadHarvest.widthFeet'
            textAlign='right'
            {...register('loadHarvest.widthFeet', { valueAsNumber: true, required: true })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='loadHarvest.lengthFeet'>{t('lengthFeet')}</FormLabel>
          <Input
            id='loadHarvest.lengthFeet'
            textAlign='right'
            {...register('loadHarvest.lengthFeet', { valueAsNumber: true, required: true })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='loadHarvest.heightFeet'>{t('heightFeet')}</FormLabel>
          <Input
            id='loadHarvest.heightFeet'
            textAlign='right'
            {...register('loadHarvest.heightFeet', { valueAsNumber: true, required: true })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormLabel htmlFor='loadHarvest.moistureLevel'>{t('moistureLevel')}</FormLabel>
        <Input
          id='loadHarvest.moistureLevel'
          textAlign='right'
          {...register('loadHarvest.moistureLevel', { valueAsNumber: true })}
        />
      </FormRow>
    </>
  );
};
