import React from 'react';
import { useTranslation } from 'react-i18next';

import { type OptionBase, Select } from 'chakra-react-select';

import { type CultureLifecycle, useFetchCulturesQuery } from '../../../graphql/hooks';

export type CultureSelectProps = {
  cultureLifecycle: CultureLifecycle;
  onSelect: (cultureId: number | undefined) => void;
  value: number | undefined | null;
};

interface CultureOption extends OptionBase {
  readonly value: number;
  readonly label: string;
}

export const CultureSelect: React.FC<CultureSelectProps> = ({ cultureLifecycle, onSelect, value }) => {
  const { t } = useTranslation();
  const query = useFetchCulturesQuery();

  const options: readonly CultureOption[] = React.useMemo(
    () =>
      (query.data?.cultures || [])
        .filter((culture) => culture.cultureLifecycle === cultureLifecycle)
        .map((culture) => ({
          value: culture.id,
          label: culture.name,
        })),
    [query.data?.cultures, cultureLifecycle]
  );

  const selectedOption = options.find((option) => option.value === value);

  return query.isLoading ? (
    <span>{t('loading')}</span>
  ) : query.isError ? (
    <span>{t('error')}</span>
  ) : (
    <Select
      isSearchable={false}
      value={selectedOption ?? null}
      onChange={(e) => onSelect(e?.value)}
      options={options}
      placeholder={t('components.select.placeholder')}
    />
  );
};
