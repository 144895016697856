import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { type OptionBase, Select } from 'chakra-react-select';

import { useUser } from '../../contexts/UserContext';
import { useStationsWithTargetQuery } from '../../graphql/hooks';

export type StationSelectProps = {
  onSelectStation: (stationId: number | null) => void;
  season: number | null;
  value: number | null;
  isClearable?: boolean;
};

interface StationOption extends OptionBase {
  readonly value: number;
  readonly label: string;
}

export const StationSelect: React.FC<StationSelectProps> = ({ onSelectStation, season, value, isClearable }) => {
  const { t } = useTranslation();
  const user = useUser();
  const query = useStationsWithTargetQuery(
    {
      seasonId: season || 0,
      sponsorId: user?.sponsor?.id || '',
    },
    {
      enabled: season !== null,
    }
  );

  const options: readonly StationOption[] = (query.data?.stationsWithTarget || []).map((station) => {
    const participation = station.target ? ` (${station.participants || 0} / ${station.target})` : '';
    const label =
      station.id === -1 ? `${station.name}${participation}` : `${station.number} - ${station.name}${participation}`;
    return {
      value: station.id,
      label,
    };
  });
  const selectValue = options.find((option) => option.value === value);

  return query.isLoading ? (
    <span>{t('loading')}</span>
  ) : query.isError ? (
    <span>{t('error')}</span>
  ) : (
    <Select
      isSearchable
      isClearable={isClearable}
      value={selectValue}
      onChange={(e) => onSelectStation(e?.value ?? null)}
      options={options}
      placeholder={t('enrollment.station.select.placeholder')}
    />
  );
};
