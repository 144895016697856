import { createContext, type FC, type ReactNode, useContext, useEffect, useState } from 'react';

import firebase from 'firebase/compat/app';

const FirebaseContext = createContext<firebase.app.App | null | undefined>(undefined);

const FirebaseProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [firebaseApp, setFirebaseApp] = useState<firebase.app.App | null>(null);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      fetch('/__/firebase/init.json')
        .then((response) => response.json())
        .then((config) => setFirebaseApp(firebase.initializeApp(config)));
    } else {
      setFirebaseApp(
        firebase.initializeApp({
          apiKey: 'AIzaSyDGP_wB00zcjJg1pN1VjjoOMVf1h4ak-Gk',
          authDomain: 'cecpa-collecto-development.firebaseapp.com',
          projectId: 'cecpa-collecto-development',
          storageBucket: 'cecpa-collecto-development.appspot.com',
          messagingSenderId: '82127534637',
          appId: '1:82127534637:web:c62321732fabaa673056a4',
        })
      );
    }
  }, []);

  return <FirebaseContext.Provider value={firebaseApp}>{!!firebaseApp && children}</FirebaseContext.Provider>;
};

function useFirebase() {
  const firebaseApp = useContext(FirebaseContext);
  if (firebaseApp === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return firebaseApp;
}

export { FirebaseProvider, useFirebase };
