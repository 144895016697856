import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Button, Container, Flex, Image, Text } from '@chakra-ui/react';

import { CultureLifecycle, useEnrollmentReportByCipherQuery } from '../../graphql/hooks';
import i18n from '../../i18n';

import { EnrollmentReportMowings } from './EnrollmentReportMowings';

import styles from './EnrollmentReport.module.css';

export const EnrollmentReportPage: React.FC = () => {
  const { t } = useTranslation();
  const { cipher } = useParams<'cipher'>();
  const reportQuery = useEnrollmentReportByCipherQuery({ cipher: cipher ?? '' }, { enabled: !!cipher });

  const enrollment = reportQuery.data?.enrollmentReportByCipher;

  // Set the base rem size
  useEffect(() => {
    const root = document.querySelector(':root') as HTMLElement;
    if (root) {
      root.style.setProperty('font-size', '12px');
      root.style.setProperty('background-color', 'transparent');
    }
    const body = document.querySelector('body') as HTMLElement;
    if (body) {
      body.style.setProperty('background-color', 'transparent');
    }
  }, []);

  useEffect(() => {
    if (enrollment) {
      i18n.changeLanguage(enrollment.farm.language === 'F' ? 'fr-CA' : 'en-CA');
    }
  }, [enrollment]);

  if (reportQuery.isLoading || !enrollment) {
    return null;
  }

  const perennialMowings = enrollment.mowings.filter(
    (mowing) => mowing.cultureLifecycle === CultureLifecycle.Perennial
  );
  const annualMowings = enrollment.mowings.filter((mowing) => mowing.cultureLifecycle === CultureLifecycle.Annual);

  return (
    <Container className={styles.page} width='980px'>
      <Flex alignItems='center'>
        <Box flex='1'>
          <Flex alignItems='baseline'>
            <Text fontSize='2xl' casing='uppercase'>
              {t('enrollment.report.title')}
            </Text>
            <Text fontSize='4xl' color='cecpaGreen.500' ml={10}>
              {t('enrollment.report.season', { year: new Date(enrollment.season.startsAt).getFullYear() })}
            </Text>
          </Flex>
        </Box>
        <Box>
          <Image srcSet='/CECPA-LOGO-RGB.png 1x, /CECPA-LOGO-RGB@2x.png 2x' />
        </Box>
      </Flex>
      <Box mb='16'>
        <Flex alignItems='flex-end'>
          <Box flex='auto'>
            <Flex>
              <Text>{t('enrollment.report.header.clinum')}</Text>
              <Text fontWeight={700} pl='2'>
                {enrollment.farm.number}
              </Text>
            </Flex>
            <Flex>
              <Text>{t('enrollment.report.header.name')}</Text>
              <Text fontWeight={700} pl='2'>
                {enrollment.farm.accountName}
              </Text>
            </Flex>
            <Flex>
              <Text>{t('enrollment.report.header.station')}</Text>
              <Text fontWeight={700} pl='2'>
                {enrollment.farm.station.name}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Button onClick={() => window.print()} className={styles.button} variant='solid' colorScheme='blue'>
              {t('enrollment.report.header.print')}
            </Button>
          </Box>
        </Flex>
      </Box>
      {perennialMowings.length > 0 && (
        <EnrollmentReportMowings
          mowings={perennialMowings}
          cultureLifecycle={CultureLifecycle.Perennial}
          towerSilos={enrollment.farm.towerSilos}
        />
      )}
      {annualMowings.length > 0 && (
        <EnrollmentReportMowings
          mowings={annualMowings}
          cultureLifecycle={CultureLifecycle.Annual}
          towerSilos={enrollment.farm.towerSilos}
        />
      )}
    </Container>
  );
};
