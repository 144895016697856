import type * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { FormRow } from '../../Form';
import { ChamberTypeSelect } from '../Widgets/ChamberTypeSelect';
import { RotoCutSelect } from '../Widgets/RotoCutSelect';

import { eitherRequired } from './Validation';

export const DryRoundBaleHarvestForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'enrollment.editor.harvests.form' });
  const { control, register, getValues } = useFormContext();

  return (
    <>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='dryRoundBaleHarvest.chamberType'>{t('chamberType')}</FormLabel>
          <Controller
            name='dryRoundBaleHarvest.chamberType'
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => <ChamberTypeSelect value={value} onChange={onChange} />}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='dryRoundBaleHarvest.rotoCut'>{t('rotoCut')}</FormLabel>
          <Controller
            name='dryRoundBaleHarvest.rotoCut'
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => <RotoCutSelect value={value} onChange={onChange} />}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='dryRoundBaleHarvest.widthFeet'>{t('widthFeet')}</FormLabel>
          <Input
            id='dryRoundBaleHarvest.widthFeet'
            textAlign='right'
            {...register('dryRoundBaleHarvest.widthFeet', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('dryRoundBaleHarvest.widthFeet'),
                  getValues('dryRoundBaleHarvest.widthInches')
                ),
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='dryRoundBaleHarvest.widthInches'>{t('widthInches')}</FormLabel>
          <Input
            id='dryRoundBaleHarvest.widthInches'
            textAlign='right'
            {...register('dryRoundBaleHarvest.widthInches', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('dryRoundBaleHarvest.widthFeet'),
                  getValues('dryRoundBaleHarvest.widthInches')
                ),
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='dryRoundBaleHarvest.diameterFeet'>{t('diameterFeet')}</FormLabel>
          <Input
            id='dryRoundBaleHarvest.diameterFeet'
            textAlign='right'
            {...register('dryRoundBaleHarvest.diameterFeet', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('dryRoundBaleHarvest.diameterFeet'),
                  getValues('dryRoundBaleHarvest.diameterInches')
                ),
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='dryRoundBaleHarvest.diameterInches'>{t('diameterInches')}</FormLabel>
          <Input
            id='dryRoundBaleHarvest.diameterInches'
            textAlign='right'
            {...register('dryRoundBaleHarvest.diameterInches', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('dryRoundBaleHarvest.diameterFeet'),
                  getValues('dryRoundBaleHarvest.diameterInches')
                ),
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormLabel htmlFor='dryRoundBaleHarvest.bales'>{t('bales')}</FormLabel>
        <Input
          id='dryRoundBaleHarvest.bales'
          textAlign='right'
          {...register('dryRoundBaleHarvest.bales', { valueAsNumber: true, required: true })}
        />
      </FormRow>
    </>
  );
};
