import type * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Container,
  Divider,
  Fade,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Textarea,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import {
  type BagHarvestFragment,
  type BagHarvestInput,
  type BunkerSiloHarvestFragment,
  type BunkerSiloHarvestInput,
  CultureLifecycle,
  type DryRoundBaleHarvestFragment,
  type DryRoundBaleHarvestInput,
  type HarvestSummaryFragment,
  HarvestType,
  type LargeDrySquareBaleHarvestFragment,
  type LargeDrySquareBaleHarvestInput,
  type LargeMoistSquareBaleHarvestFragment,
  type LargeMoistSquareBaleHarvestInput,
  type LoadHarvestFragment,
  type LoadHarvestInput,
  type MoistRoundBaleHarvestFragment,
  type MoistRoundBaleHarvestInput,
  type MowingFragment,
  type SilagePileHarvestFragment,
  type SilagePileHarvestInput,
  type SmallSquareBaleHarvestFragment,
  type SmallSquareBaleHarvestInput,
  type StandaloneHarvestInput,
  type TowerSiloHarvestFragment,
  type TowerSiloHarvestInput,
  useUpsertLotAndHarvestMutation,
} from '../../graphql/hooks';
import { FormDivider, FormHeader, FormRow } from '../Form';
import { DatePicker } from '../Form/DatePicker';

import { CultureSelect } from './Widgets/CultureSelect';
import { HarvestTypeSelect } from './Widgets/HarvestTypeSelect';
import {
  BagHarvestForm,
  BunkerSiloHarvestForm,
  DryRoundBaleHarvestForm,
  LargeDrySquareBaleHarvestForm,
  LargeMoistSquareBaleHarvestForm,
  LoadHarvestForm,
  MoistRoundBaleHarvestForm,
  SilagePileHarvestForm,
  SmallSquareBaleHarvestForm,
  TowerSiloHarvestForm,
} from './Form';

type HarvestInputFormFields = {
  harvest: StandaloneHarvestInput;
  smallSquareBaleHarvest?: SmallSquareBaleHarvestInput;
  largeMoistSquareBaleHarvest?: LargeMoistSquareBaleHarvestInput;
  largeDrySquareBaleHarvest?: LargeDrySquareBaleHarvestInput;
  moistRoundBaleHarvest?: MoistRoundBaleHarvestInput;
  dryRoundBaleHarvest?: DryRoundBaleHarvestInput;
  towerSiloHarvest?: TowerSiloHarvestInput;
  bunkerSiloHarvest?: BunkerSiloHarvestInput;
  silagePileHarvest?: SilagePileHarvestInput;
  bagHarvest?: BagHarvestInput;
  loadHarvest?: LoadHarvestInput;
};

type SmallSquareBaleHarvest = SmallSquareBaleHarvestFragment & {
  harvestType: HarvestType.SmallSquareBaleHarvest;
};
type LargeMoistSquareBaleHarvest = LargeMoistSquareBaleHarvestFragment & {
  harvestType: HarvestType.LargeMoistSquareBaleHarvest;
};
type LargeDrySquareBaleHarvest = LargeDrySquareBaleHarvestFragment & {
  harvestType: HarvestType.LargeDrySquareBaleHarvest;
};
type MoistRoundBaleHarvest = MoistRoundBaleHarvestFragment & {
  harvestType: HarvestType.MoistRoundBaleHarvest;
};
type DryRoundBaleHarvest = DryRoundBaleHarvestFragment & {
  harvestType: HarvestType.DryRoundBaleHarvest;
};
type TowerSiloHarvest = TowerSiloHarvestFragment & {
  harvestType: HarvestType.TowerSiloHarvest;
};
type BunkerSiloHarvest = BunkerSiloHarvestFragment & {
  harvestType: HarvestType.BunkerSiloHarvest;
};
type SilagePileHarvest = SilagePileHarvestFragment & {
  harvestType: HarvestType.SilagePileHarvest;
};
type BagHarvest = BagHarvestFragment & {
  harvestType: HarvestType.BagHarvest;
};
type LoadHarvest = LoadHarvestFragment & {
  harvestType: HarvestType.LoadHarvest;
};

export type AnyHarvest = HarvestSummaryFragment &
  (
    | SmallSquareBaleHarvest
    | LargeMoistSquareBaleHarvest
    | LargeDrySquareBaleHarvest
    | MoistRoundBaleHarvest
    | DryRoundBaleHarvest
    | TowerSiloHarvest
    | BunkerSiloHarvest
    | SilagePileHarvest
    | BagHarvest
    | LoadHarvest
  );

export type HarvestEditFormProps = {
  seasonId: number;
  farmId: number;
  mowing: MowingFragment;
  harvest: AnyHarvest | null;
  onSave?: (id: number) => void;
  closeOnSave: () => void;
};

export const HarvestEditForm: React.FC<HarvestEditFormProps> = ({
  farmId,
  seasonId,
  mowing,
  harvest,
  onSave,
  closeOnSave,
}) => {
  const { t, i18n } = useTranslation();
  const numberFormat = useMemo(() => new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 2 }), [i18n]);
  const queryClient = useQueryClient();
  const shouldCloseOnSave = useRef(false);

  const form = useForm<HarvestInputFormFields>({ shouldUnregister: true, mode: 'all' });
  const selectedHarvestType = form.watch('harvest.harvestType');

  const initialize = useCallback(() => {
    const input: HarvestInputFormFields = {
      harvest: {
        lotName: '',
        date: harvest?.date ?? '',
        harvestType: harvest != null ? HarvestType[harvest.harvestType] : (null as unknown as HarvestType), // temporarily allow null
        field: harvest?.field,
        hectares: harvest?.hectares || 0,
        comments: harvest?.comments,
        cultureId: harvest?.culture.id ?? -1,
      },
    };

    if (harvest != null) {
      switch (harvest.harvestType) {
        case HarvestType.SmallSquareBaleHarvest:
          input.smallSquareBaleHarvest = {
            bales: harvest.bales,
          };
          break;
        case HarvestType.LargeMoistSquareBaleHarvest:
          input.largeMoistSquareBaleHarvest = {
            bales: harvest.bales,
            widthFeet: harvest.widthFeet,
            widthInches: harvest.widthInches,
            lengthFeet: harvest.lengthFeet,
            lengthInches: harvest.lengthInches,
            heightFeet: harvest.heightFeet,
            heightInches: harvest.heightInches,
            moistureLevel: harvest.moistureLevel,
          };
          break;
        case HarvestType.LargeDrySquareBaleHarvest:
          input.largeDrySquareBaleHarvest = {
            bales: harvest.bales,
            widthFeet: harvest.widthFeet,
            widthInches: harvest.widthInches,
            lengthFeet: harvest.lengthFeet,
            lengthInches: harvest.lengthInches,
            heightFeet: harvest.heightFeet,
            heightInches: harvest.heightInches,
          };
          break;
        case HarvestType.MoistRoundBaleHarvest:
          input.moistRoundBaleHarvest = {
            bales: harvest.bales,
            chamberType: harvest.chamberType,
            rotoCut: harvest.rotoCut,
            widthFeet: harvest.widthFeet,
            widthInches: harvest.widthInches,
            diameterFeet: harvest.diameterFeet,
            diameterInches: harvest.diameterInches,
            moistureLevel: harvest.moistureLevel,
          };
          break;
        case HarvestType.DryRoundBaleHarvest:
          input.dryRoundBaleHarvest = {
            bales: harvest.bales,
            chamberType: harvest.chamberType,
            rotoCut: harvest.rotoCut,
            diameterFeet: harvest.diameterFeet,
            diameterInches: harvest.diameterInches,
            widthFeet: harvest.widthFeet,
            widthInches: harvest.widthInches,
          };
          break;
        case HarvestType.TowerSiloHarvest:
          input.towerSiloHarvest = {
            siloNumber: harvest.siloNumber,
            diameterFeet: harvest.diameterFeet || 0,
            startingHeight: harvest.startingHeight,
            endingHeight: harvest.endingHeight,
            dailyFeed: harvest.dailyFeed,
            days: harvest.days,
            moistureLevel: harvest.moistureLevel,
          };
          break;
        case HarvestType.BunkerSiloHarvest:
          input.bunkerSiloHarvest = {
            widthFeet: harvest.widthFeet,
            lengthFeet: harvest.lengthFeet,
            heightFeet: harvest.heightFeet,
            moistureLevel: harvest.moistureLevel,
          };
          break;
        case HarvestType.SilagePileHarvest:
          input.silagePileHarvest = {
            widthFeet: harvest.widthFeet,
            lengthFeet: harvest.lengthFeet,
            heightFeet: harvest.heightFeet,
            moistureLevel: harvest.moistureLevel,
          };
          break;
        case HarvestType.BagHarvest:
          input.bagHarvest = {
            diameterFeet: harvest.diameterFeet,
            lengthFeet: harvest.lengthFeet,
            moistureLevel: harvest.moistureLevel,
          };
          break;
        case HarvestType.LoadHarvest:
          input.loadHarvest = {
            loads: harvest.loads,
            widthFeet: harvest.widthFeet,
            lengthFeet: harvest.lengthFeet,
            heightFeet: harvest.heightFeet,
            moistureLevel: harvest.moistureLevel,
          };
          break;
      }
    }
    form.reset(input);
  }, [form, harvest]);

  useEffect(() => initialize(), [initialize]);

  const upsertLotAndHarvestMutation = useUpsertLotAndHarvestMutation({
    onSuccess: (data) => {
      onSave?.(data.upsertStandaloneHarvest.id);
      if (shouldCloseOnSave.current) {
        closeOnSave();
      }
    },
  });

  const onSubmit: SubmitHandler<HarvestInputFormFields> = async ({ harvest: genericHarvest, ...specificData }) => {
    await upsertLotAndHarvestMutation.mutateAsync({
      seasonId,
      farmId,
      lotInput: {
        name: harvest?.lot.name ?? 'Lot automatique',
        parcelIds: harvest?.lot.parcels.map((parcel) => parcel.id) ?? [],
        hectares: harvest?.lot.hectares ?? 0,
      },
      id: harvest?.id,
      mowingNumber: mowing.number,
      harvest: {
        ...genericHarvest,
        lotName: harvest?.lot.name ?? 'Lot automatique',
      },
      ...specificData,
    });

    await queryClient.invalidateQueries({ queryKey: ['FetchHarvest', { id: harvest?.id }] });
  };

  // eslint-disable-next-line consistent-return
  const renderSpecificForm = () => {
    switch (selectedHarvestType) {
      case HarvestType.SmallSquareBaleHarvest:
        return <SmallSquareBaleHarvestForm />;
      case HarvestType.LargeMoistSquareBaleHarvest:
        return <LargeMoistSquareBaleHarvestForm />;
      case HarvestType.LargeDrySquareBaleHarvest:
        return <LargeDrySquareBaleHarvestForm />;
      case HarvestType.MoistRoundBaleHarvest:
        return <MoistRoundBaleHarvestForm />;
      case HarvestType.DryRoundBaleHarvest:
        return <DryRoundBaleHarvestForm />;
      case HarvestType.TowerSiloHarvest:
        return <TowerSiloHarvestForm farmId={farmId} saved={!!harvest} />;
      case HarvestType.BunkerSiloHarvest:
        return <BunkerSiloHarvestForm />;
      case HarvestType.SilagePileHarvest:
        return <SilagePileHarvestForm />;
      case HarvestType.BagHarvest:
        return <BagHarvestForm />;
      case HarvestType.LoadHarvest:
        return <LoadHarvestForm />;
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <Container px={{ base: 0, md: 10 }} mb='170px' maxWidth='6xl'>
          <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
            <Stack>
              <FormHeader text={t('enrollment.editor.harvests.form.title')} />
              <Divider />
              <FormRow templateColumns='15rem' spacing={0}>
                <FormLabel htmlFor='harvest.date'>{t('enrollment.editor.harvests.form.date')}</FormLabel>
                <Controller
                  name='harvest.date'
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value ? new Date(value) : null}
                      onChange={(date) => onChange(date?.toISOString())}
                      popperPlacement='right'
                    />
                  )}
                />
              </FormRow>
              <FormRow templateColumns='15rem' spacing={0}>
                <FormLabel htmlFor='harvest.hectares'>{t('enrollment.editor.harvests.form.hectares')}</FormLabel>
                <Input
                  id='harvest.hectares'
                  textAlign='right'
                  {...form.register('harvest.hectares', {
                    valueAsNumber: true,
                    required: true,
                    validate: (value) => (value ?? 0) > 0,
                  })}
                />
              </FormRow>
              <FormRow templateColumns='30rem' spacing={0}>
                <FormLabel htmlFor='harvest.harvestType'>{t('enrollment.editor.harvests.form.harvestType')}</FormLabel>
                <Controller
                  name='harvest.harvestType'
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <HarvestTypeSelect value={value} onChange={onChange} isDisabled={!!harvest} />
                  )}
                />
              </FormRow>
              <Box>
                {mowing.cultureLifecycle === CultureLifecycle.Annual && (
                  <FormDivider text={t('enrollment.editor.harvests.form.culture')} />
                )}
                <FormRow templateColumns='30rem'>
                  <FormControl>
                    <FormLabel htmlFor='harvest.cultureId'>{t('enrollment.editor.harvests.form.cultureId')}</FormLabel>
                    <Controller
                      name='harvest.cultureId'
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <CultureSelect cultureLifecycle={mowing.cultureLifecycle} value={value} onSelect={onChange} />
                      )}
                    />
                  </FormControl>
                </FormRow>
              </Box>
              <FormDivider text={t('enrollment.editor.harvests.form.dimensions')} />
              {renderSpecificForm()}
              <FormRow templateColumns='1fr' spacing={0}>
                <FormControl>
                  <FormLabel htmlFor='harvest.comments'>{t('enrollment.editor.harvests.form.comments')}</FormLabel>
                  <Textarea id='harvest.comments' {...form.register('harvest.comments')} />
                  <FormHelperText>{t('enrollment.editor.harvests.help.comments')}</FormHelperText>
                </FormControl>
              </FormRow>
              <FormDivider text={t('enrollment.editor.harvests.form.estimation')} />
              <FormRow templateColumns='15rem 15rem 15rem 15rem'>
                <Stat>
                  <StatLabel>{t('enrollment.editor.harvests.form.baleWeight')}</StatLabel>
                  <StatNumber>{numberFormat.format(harvest?.baleWeight || 0)}</StatNumber>
                  <StatHelpText>{t('enrollment.editor.mowings.weightUnit')}</StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>{t('enrollment.editor.harvests.form.calculatedYield')}</StatLabel>
                  <StatNumber>{numberFormat.format(harvest?.calculatedYield || 0)}</StatNumber>
                  <StatHelpText>{t('enrollment.editor.mowings.weightUnit')}</StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>{t('enrollment.editor.harvests.form.hectares')}</StatLabel>
                  <StatNumber>{numberFormat.format(harvest?.hectares || 0)}</StatNumber>
                  <StatHelpText>{t('enrollment.editor.mowings.harvestedHectaresUnit')}</StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>{t('enrollment.editor.harvests.form.yieldPerHectare')}</StatLabel>
                  <StatNumber>{numberFormat.format(harvest?.yieldPerHectare || 0)}</StatNumber>
                  <StatHelpText>{t('enrollment.editor.mowings.yieldUnit')}</StatHelpText>
                </Stat>
              </FormRow>
            </Stack>
          </Box>
        </Container>
        <Fade in={form.formState.isDirty}>
          <Box bottom='0' position='fixed' left='0' w='100%' py={10} px={14} bg='bg.surface'>
            <Flex>
              <Spacer />
              <HStack spacing={5}>
                <Button variant='tertiary' onClick={initialize}>
                  {t('enrollment.editor.actions.cancel')}
                </Button>
                <Button
                  variant='solid'
                  colorScheme='blue'
                  isLoading={form.formState.isSubmitting}
                  isDisabled={!form.formState.isValid}
                  onClick={form.handleSubmit(onSubmit)}
                >
                  {t('enrollment.editor.actions.save')}
                </Button>
                <Button
                  variant='solid'
                  colorScheme='blue'
                  isLoading={form.formState.isSubmitting}
                  isDisabled={!form.formState.isValid}
                  onClick={async () => {
                    shouldCloseOnSave.current = true;
                    await form.handleSubmit(onSubmit)();
                  }}
                >
                  {t('enrollment.editor.actions.saveAndClose')}
                </Button>
              </HStack>
            </Flex>
          </Box>
        </Fade>
      </form>
    </FormProvider>
  );
};
