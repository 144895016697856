import type * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddIcon, DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Fade,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Spacer,
  Stack,
  StackDivider,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useTheme,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';

import {
  AddressType,
  type AddressUpdateInput,
  type EnrollmentForEditorQuery,
  type EnrollmentUpdateInput,
  type TowerSiloUpdateInput,
  useAddFarmParcelsMutation,
  useEnrollmentForEditorQuery,
  type UserInput,
  useUpdateEnrollmentAndAddressesMutation,
} from '../../graphql/hooks';
import { invalidateQueries } from '../../services/cache';
import { BooleanSelect } from '../BooleanSelect';
import { CitySelect } from '../City';
import { FormDivider, FormHeader, FormRow } from '../Form';
import { LanguageSelect } from '../LanguageSelect';

import { PhoneNumberInput } from './Widgets/PhoneNumberInput';
import { useValidatePhoneNumberIsAvailable } from './Widgets/useValidatePhoneNumberIsAvailable';
import { EnrollmentStatusSelect } from './EnrollmentStatusSelect';

export type EnrollmentEditorProps = {
  id: string | undefined;
  onClose: () => void;
  setTitle: (value: string) => void;
  setDirty: (value: boolean) => void;
};

type EnrollmentFormFields = {
  enrollment: EnrollmentUpdateInput;
  mailAddress: AddressUpdateInput;
  operationAddress: AddressUpdateInput;
  towerSilos: TowerSiloUpdateInput[];
  users: Array<
    UserInput & {
      readonly id?: string;
      readonly lastConnection?: string | null;
      readonly isPersisted: boolean;
    }
  >;
};

export const EnrollmentEditor: React.FC<EnrollmentEditorProps> = ({ id, onClose, setTitle, setDirty }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dateTimeFormat = useMemo(() => new Intl.DateTimeFormat(i18n.language), [i18n.language]);

  const [loaded, setLoaded] = useState(false);
  const [forceReload, setForceReload] = useState(false);
  const [closeOnSuccess, setCloseOnSuccess] = useState(false);

  const enrollmentQuery = useEnrollmentForEditorQuery(
    { id: Number(id) },
    {
      enabled: id !== undefined,
    }
  );
  const enrollment = enrollmentQuery.data?.enrollmentById;

  useEffect(() => {
    if (enrollmentQuery.isSuccess && !forceReload) {
      setLoaded(false);
      setForceReload(false);
    }
  }, [enrollmentQuery.isSuccess, forceReload]);

  const queryClient = useQueryClient();
  const invalidate = async () => {
    if (closeOnSuccess) {
      onClose();
    } else {
      setForceReload(true);
    }
    await invalidateQueries(queryClient);
  };

  const enrollmentMutation = useUpdateEnrollmentAndAddressesMutation({ onSuccess: invalidate });

  const {
    control,
    handleSubmit,
    register,
    reset,
    setFocus,
    formState: { isDirty, isSubmitting, isValid },
  } = useForm<EnrollmentFormFields>({ mode: 'onChange' });

  const resetFormState = useCallback(
    (queryData: NonNullable<EnrollmentForEditorQuery['enrollmentById']>) => {
      const mailAddress = queryData.farm.addresses.find((address) => address.type === AddressType.Mail);
      const operationAddress = queryData.farm.addresses.find((address) => address.type === AddressType.Operation);
      // Mapping of enrollment query and mutation input is not 1:1, hence the
      // manual setting of fields here
      reset({
        enrollment: {
          annualCulture: queryData.annualCulture,
          annualCultureHectares: queryData.annualCultureHectares,
          saleIntent: queryData.saleIntent,
          salePrice: queryData.salePrice,
          nextSeasonInterest: queryData.nextSeasonInterest,
          expectedMowings: queryData.expectedMowings,
          status: queryData.status,
          farm: {
            comments: queryData.farm.comments,
            contactPreference: queryData.farm.contactPreference,
            customerFirstName: queryData.farm.customerFirstName,
            customerLastName: queryData.farm.customerLastName,
            email: queryData.farm.email,
            hasInterest: queryData.farm.hasInterest,
            language: queryData.farm.language,
            mobilePhone: queryData.farm.mobilePhone,
            primaryPhone: queryData.farm.primaryPhone,
            secondaryPhone: queryData.farm.secondaryPhone,
          },
        },
        mailAddress: {
          cityId: mailAddress?.city?.id,
          postalCode: mailAddress?.postalCode,
          street: mailAddress?.street,
        },
        operationAddress: {
          cityId: operationAddress?.city?.id,
          postalCode: operationAddress?.postalCode,
          street: operationAddress?.street,
          phone: operationAddress?.phone,
        },
        users: queryData.farm.users
          .filter((user) => !!user.phoneNumber)
          .map((user) => ({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,

            phoneNumber: formatIncompletePhoneNumber(user.phoneNumber!, 'CA'),
            lastConnection: user.lastConnection,
            isPersisted: true,
            isValid: true,
            isAvailable: true,
          })),
        towerSilos: queryData.farm.towerSilos.map((towerSilo) => ({
          number: towerSilo.number,
          diameter: towerSilo.diameter,
          height: towerSilo.height,
          isActive: towerSilo.isActive,
        })),
      });
    },
    [reset]
  );

  const parcelsStringRef = useRef<HTMLInputElement>(null);
  const addParcelsMutation = useAddFarmParcelsMutation({ onSuccess: invalidate });
  const onAddParcels = async () => {
    const parcelsString: string = parcelsStringRef.current?.value ?? '';
    const parcels = parcelsString
      .split(',')
      .map((parcel) => parcel.trim())
      .map((name) => ({ name }));
    if (parcels.length === 0) {
      return;
    }
    await addParcelsMutation.mutateAsync({ id: enrollment!.farm.id, seasonId: enrollment!.season.id, parcels });
    parcelsStringRef.current!.value = '';
  };

  const { fields: towerSiloRows, append: appendTowerSilo } = useFieldArray({ name: 'towerSilos', control });
  const { fields: userRows, append: appendUser, remove: removeUser } = useFieldArray({ name: 'users', control });

  const validatePhoneNumberIsAvailable = useValidatePhoneNumberIsAvailable();

  // Set page title on parent
  useEffect(() => {
    if (enrollment) {
      setTitle(enrollment.farm.accountName);
    }
  }, [enrollment, setTitle]);

  // Set dirty state on parent
  useEffect(() => setDirty(isDirty), [isDirty, setDirty]);

  // Set default values
  useEffect(() => {
    if (!loaded && enrollment) {
      setLoaded(true);
      resetFormState(enrollment);
    }
  }, [loaded, resetFormState, enrollment]);

  // TODO: Error management
  if (enrollmentQuery.isLoading || !enrollment) {
    return <div />;
  }

  // Split form into its mutation, submit
  const onSubmit: SubmitHandler<EnrollmentFormFields> = async (data) => {
    const enrollmentId = enrollment.id;
    const farmId = enrollment.farm.id;
    const {
      enrollment: { farm, ...enrollmentFields },
      mailAddress,
      operationAddress,
      towerSilos,
      users,
    } = data;
    return enrollmentMutation.mutateAsync({
      enrollmentId,
      farmId,
      enrollmentData: { ...enrollmentFields, farm },
      mailAddressData: mailAddress,
      operationAddressData: operationAddress,
      towerSilosData: towerSilos,
      users: users
        .filter((user) => !!user.phoneNumber)
        .map(({ phoneNumber, firstName, lastName }) => ({ phoneNumber, firstName, lastName })),
    });
  };

  const reportUrl = `${window.location.origin}/enrollmentReport/${enrollment.reportCipher}`;
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Container px={{ base: 0, md: 10 }} mb='170px'>
        <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
          <Stack divider={<StackDivider />}>
            <FormHeader text={t('enrollment.editor.headers.business')} />
            <Stack mb={3}>
              <FormRow templateColumns='10rem 1fr 10rem 10rem'>
                <FormControl>
                  <FormLabel htmlFor='selectionOrder'>{t('enrollment.editor.fields.selectionOrder')}</FormLabel>
                  <Input
                    id='selectionOrder'
                    variant='filled'
                    isReadOnly
                    value={enrollment.selectionOrder?.toString()}
                  />
                </FormControl>
                <Box />
                <FormControl>
                  <FormLabel htmlFor='farmNumber'>{t('enrollment.editor.fields.farmNumber')}</FormLabel>
                  <Input
                    id='farmNumber'
                    isReadOnly
                    onFocus={(e) => e.target.select()}
                    value={enrollment.farm.number || ''}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='farmMinistryNumber'>{t('enrollment.editor.fields.farmMinistryNumber')}</FormLabel>
                  <Input
                    id='farmMinistryNumber'
                    isReadOnly
                    onFocus={(e) => e.target.select()}
                    value={enrollment.farm.ministry_number || ''}
                  />
                </FormControl>
              </FormRow>
              <FormRow templateColumns='1fr 10rem'>
                <FormControl>
                  <FormLabel htmlFor='accountName'>{t('enrollment.editor.fields.accountName')}</FormLabel>
                  <Input id='accountName' isReadOnly value={enrollment.farm.accountName} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='hectares'>{t('enrollment.editor.fields.hectares')}</FormLabel>
                  <Input id='hectares' isReadOnly textAlign='right' value={enrollment.farm.hectares} />
                </FormControl>
              </FormRow>
              <FormRow templateColumns='1fr 1fr 1fr'>
                <FormControl>
                  <FormLabel htmlFor='station'>{t('enrollment.editor.fields.station')}</FormLabel>
                  <Input
                    id='station'
                    isReadOnly
                    value={
                      enrollment.farm.station.id > 0
                        ? `${enrollment.farm.station.number} - ${enrollment.farm.station.name}`
                        : enrollment.farm.station.name
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='serviceCenter'>{t('enrollment.editor.fields.serviceCenter')}</FormLabel>
                  <Input
                    id='serviceCenter'
                    isReadOnly
                    value={
                      enrollment.farm.serviceCenter.id > 0
                        ? `${enrollment.farm.serviceCenter.number} - ${enrollment.farm.serviceCenter.name}`
                        : ''
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='region'>{t('enrollment.editor.fields.region')}</FormLabel>
                  <Input
                    id='region'
                    isReadOnly
                    value={
                      enrollment.farm.region.id > 0
                        ? `${enrollment.farm.region.number} - ${enrollment.farm.region.name}`
                        : ''
                    }
                  />
                </FormControl>
              </FormRow>
              <FormRow templateColumns='1fr'>
                <FormControl>
                  <FormLabel htmlFor='reportUrl'>{t('enrollment.editor.fields.reportUrl')}</FormLabel>
                  <InputGroup>
                    <Input id='reportUrl' pr='4.5rem' isReadOnly value={reportUrl} onFocus={(e) => e.target.select()} />
                    <InputRightElement w='3rem'>
                      <IconButton
                        aria-label={t('enrollment.editor.fields.reportUrl')}
                        h='1.75rem'
                        size='sm'
                        icon={<ExternalLinkIcon />}
                        onClick={() => window.open(reportUrl, '_blank')}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </FormRow>
            </Stack>
          </Stack>
        </Box>
        <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
          <Stack divider={<StackDivider />}>
            <Box p='6' pb='3'>
              <Text fontSize='lg' fontWeight='bold'>
                {t('enrollment.editor.headers.application')}
              </Text>
            </Box>
            <Stack mb={3}>
              <FormRow templateColumns='10rem 10rem'>
                <FormControl>
                  <FormLabel htmlFor='hasInterest'>{t('enrollment.editor.fields.hasInterest')}</FormLabel>
                  <Controller
                    name='enrollment.farm.hasInterest'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <BooleanSelect value={value ?? null} onChange={onChange} />
                    )}
                  />
                </FormControl>
              </FormRow>
              <FormDivider text={t('enrollment.editor.dividers.users')} />
              {userRows.length > 0 && (
                <Table>
                  <Thead>
                    <Tr>
                      <Th textTransform='uppercase'>{t('enrollment.editor.fields.users.phoneNumber')}</Th>
                      <Th textTransform='uppercase'>{t('enrollment.editor.fields.users.firstName')}</Th>
                      <Th textTransform='uppercase'>{t('enrollment.editor.fields.users.lastName')}</Th>
                      <Th textTransform='uppercase' textAlign='right'>
                        {t('enrollment.editor.fields.users.lastConnection')}
                      </Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {userRows.map((user, index) => (
                      <Tr key={user.id ?? user.phoneNumber}>
                        <Td>
                          <PhoneNumberInput
                            control={control}
                            name={`users.${index}.phoneNumber`}
                            isReadOnly={user.isPersisted}
                            rules={
                              user.isPersisted
                                ? {}
                                : {
                                    validate: { phone: validatePhoneNumberIsAvailable },
                                  }
                            }
                          />
                        </Td>
                        <Td>
                          <FormControl>
                            <Input {...register(`users.${index}.firstName`)} />
                          </FormControl>
                        </Td>
                        <Td>
                          <FormControl>
                            <Input {...register(`users.${index}.lastName`)} />
                          </FormControl>
                        </Td>
                        <Td>
                          {user.lastConnection && (
                            <Text textAlign='right'>{dateTimeFormat.format(new Date(user.lastConnection))}</Text>
                          )}
                        </Td>
                        <Td>
                          <IconButton
                            aria-label={t('enrollment.editor.fields.users.remove')}
                            variant='unstyled'
                            icon={<DeleteIcon />}
                            onClick={() => {
                              removeUser(index);
                            }}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
              <HStack px='1.5rem' pt='0.5rem' pb='1rem'>
                <Button
                  variant='solid'
                  size='xs'
                  leftIcon={<AddIcon />}
                  colorScheme='blue'
                  onClick={() => {
                    setTimeout(() => {
                      setFocus(`users.${userRows.length}.phoneNumber`);
                    });
                    appendUser({ phoneNumber: '', isPersisted: false });
                  }}
                >
                  {t('enrollment.editor.fields.users.add')}
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </Box>
        <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
          <Stack divider={<StackDivider />}>
            <FormHeader
              text={t('enrollment.editor.headers.communication')}
              dropdown={
                <Box w='14rem'>
                  <Controller
                    name='enrollment.status'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <EnrollmentStatusSelect value={value} onChange={onChange} />
                    )}
                  />
                </Box>
              }
            />
            <Stack>
              <FormRow templateColumns='10rem 10rem 16rem'>
                <FormControl>
                  <FormLabel htmlFor='expectedMowings'>{t('enrollment.editor.fields.expectedMowings')}</FormLabel>
                  <Input
                    id='expectedMowings'
                    textAlign='right'
                    {...register('enrollment.expectedMowings', { valueAsNumber: true })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='annualCulture'>{t('enrollment.editor.fields.annualCulture')}</FormLabel>
                  <Controller
                    name='enrollment.annualCulture'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <BooleanSelect value={value ?? null} onChange={onChange} />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='annualCultureHectares'>
                    {t('enrollment.editor.fields.annualCultureHectares')}
                  </FormLabel>
                  <Input
                    id='annualCultureHectares'
                    textAlign='right'
                    {...register('enrollment.annualCultureHectares', { valueAsNumber: true })}
                  />
                </FormControl>
              </FormRow>
              <FormRow templateColumns='10rem 10rem 16rem'>
                <FormControl>
                  <FormLabel htmlFor='saleIntent'>{t('enrollment.editor.fields.saleIntent')}</FormLabel>
                  <Controller
                    name='enrollment.saleIntent'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <BooleanSelect value={value ?? null} onChange={onChange} />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='salePrice'>{t('enrollment.editor.fields.salePrice')}</FormLabel>
                  <InputGroup>
                    <Input
                      id='enrollment.salePrice'
                      textAlign='right'
                      {...register('enrollment.salePrice', { valueAsNumber: true })}
                    />
                    <InputRightAddon>$</InputRightAddon>
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='nextSeasonInterest'>{t('enrollment.editor.fields.nextSeasonInterest')}</FormLabel>
                  <Controller
                    name='enrollment.nextSeasonInterest'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <BooleanSelect value={value ?? null} onChange={onChange} />
                    )}
                  />
                </FormControl>
              </FormRow>
              <FormRow templateColumns='1fr 10rem'>
                <FormControl>
                  <FormLabel htmlFor='contactPreference'>
                    {t('enrollment.editor.fields.contactPreference.label')}
                  </FormLabel>
                  <Input
                    id='contactPreference'
                    placeholder={t('enrollment.editor.fields.contactPreference.placeholder')}
                    {...register('enrollment.farm.contactPreference')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='language'>{t('enrollment.editor.fields.language')}</FormLabel>
                  <Controller
                    name='enrollment.farm.language'
                    control={control}
                    render={({ field: { onChange, value } }) => <LanguageSelect value={value} onChange={onChange} />}
                  />
                </FormControl>
              </FormRow>
              <FormDivider text={t('enrollment.editor.dividers.corresponding')} />
              <FormRow columns={3}>
                <FormControl>
                  <FormLabel htmlFor='customerFirstName'>{t('enrollment.editor.fields.customerFirstName')}</FormLabel>
                  <Input id='customerFirstName' {...register('enrollment.farm.customerFirstName')} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='customerLastName'>{t('enrollment.editor.fields.customerLastName')}</FormLabel>
                  <Input id='customerLastName' {...register('enrollment.farm.customerLastName')} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='email'>{t('enrollment.editor.fields.email')}</FormLabel>
                  <Input id='email' type='email' {...register('enrollment.farm.email')} />
                </FormControl>
              </FormRow>
              <FormRow templateColumns='10rem 10rem 10rem'>
                <FormControl>
                  <FormLabel htmlFor='primaryPhone'>{t('enrollment.editor.fields.primaryPhone')}</FormLabel>
                  <Input id='primaryPhone' type='tel' {...register('enrollment.farm.primaryPhone')} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='secondaryPhone'>{t('enrollment.editor.fields.secondaryPhone')}</FormLabel>
                  <Input id='secondaryPhone' type='tel' {...register('enrollment.farm.secondaryPhone')} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='mobilePhone'>{t('enrollment.editor.fields.mobilePhone')}</FormLabel>
                  <Input id='mobilePhone' type='tel' {...register('enrollment.farm.mobilePhone')} />
                </FormControl>
              </FormRow>
              <FormRow templateColumns='1fr 1fr 10rem'>
                <FormControl>
                  <FormLabel htmlFor='mailAddressStreet'>{t('enrollment.editor.fields.mailAddressStreet')}</FormLabel>
                  <Input id='mailAddressStreet' {...register('mailAddress.street')} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='mailAddressCity'>{t('enrollment.editor.fields.city')}</FormLabel>
                  <Controller
                    name='mailAddress.cityId'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CitySelect value={value} onChange={onChange} onBlur={onBlur} />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='mailAddressPostalCode'>{t('enrollment.editor.fields.postalCode')}</FormLabel>
                  <Input id='mailAddressPostalCode' {...register('mailAddress.postalCode')} />
                </FormControl>
              </FormRow>
              <FormDivider text={t('enrollment.editor.dividers.operations')} />
              <FormRow templateColumns='1fr 1fr 10rem'>
                <FormControl>
                  <FormLabel htmlFor='operationAddressStreet'>
                    {t('enrollment.editor.fields.operationAddressStreet')}
                  </FormLabel>
                  <Input id='operationAddressStreet' {...register('operationAddress.street')} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='operationAddressCity'>{t('enrollment.editor.fields.city')}</FormLabel>
                  <Controller
                    name='operationAddress.cityId'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CitySelect value={value} onChange={onChange} onBlur={onBlur} />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='operationAddressPostalCode'>{t('enrollment.editor.fields.postalCode')}</FormLabel>
                  <Input id='operationAddressPostalCode' {...register('operationAddress.postalCode')} />
                </FormControl>
              </FormRow>
              <FormRow templateColumns='10rem'>
                <FormControl>
                  <FormLabel htmlFor='operationPhone'>{t('enrollment.editor.fields.operationPhone')}</FormLabel>
                  <Input id='operationPhone' type='tel' {...register('operationAddress.phone')} />
                </FormControl>
              </FormRow>
              <FormDivider text={t('enrollment.editor.dividers.comments')} />
              <Flex p='6'>
                <FormControl>
                  <Textarea
                    rows={5}
                    id='comments'
                    placeholder={t('enrollment.editor.fields.comments')}
                    {...register('enrollment.farm.comments')}
                  />
                  <FormHelperText>{t('enrollment.editor.help.comments')}</FormHelperText>
                </FormControl>
              </Flex>
            </Stack>
          </Stack>
        </Box>
        <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
          <Stack divider={<StackDivider />}>
            <Box p='6' pb='3'>
              <Text fontSize='lg' fontWeight='bold'>
                {t('enrollment.editor.headers.parcels')}
              </Text>
            </Box>
            <Flex p={6}>
              <Wrap>
                {enrollment.farm.parcels.map((parcel) => (
                  <WrapItem key={parcel.id}>
                    <Tag
                      size='lg'
                      minWidth='3.875rem'
                      variant='outline'
                      color='cecpaGreen.700'
                      justifyContent='center'
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                      sx={{ '--badge-color': theme.colors.cecpaGreen[600] }}
                    >
                      <TagLabel>{parcel.name}</TagLabel>
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>
            </Flex>
            <Stack>
              <FormRow templateColumns='1fr'>
                <FormControl>
                  <FormLabel htmlFor='parcels'>{t('enrollment.editor.fields.addParcels.label')}</FormLabel>
                  <Input
                    id='parcels'
                    placeholder={t('enrollment.editor.fields.addParcels.placeholder')}
                    ref={parcelsStringRef}
                  />
                </FormControl>
              </FormRow>
            </Stack>
          </Stack>
          <HStack px='6' pb='1rem'>
            <Button variant='solid' size='xs' colorScheme='blue' onClick={onAddParcels}>
              {t('enrollment.editor.fields.addParcels.add')}
            </Button>
          </HStack>
        </Box>
        <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
          <Stack>
            <Box p='6' pb='3'>
              <Text fontSize='lg' fontWeight='bold'>
                {t('enrollment.editor.headers.towerSilos')}
              </Text>
            </Box>
            <Stack>
              <Table>
                <Thead>
                  <Tr>
                    <Th width='1%'>{t('enrollment.editor.fields.towerSilos.number')}</Th>
                    <Th width='1%'>{t('enrollment.editor.fields.towerSilos.isActive')}</Th>
                    <Th width='1%'>{t('enrollment.editor.fields.towerSilos.diameter')}</Th>
                    <Th width='1%'>{t('enrollment.editor.fields.towerSilos.height')}</Th>
                    <Th width='100%' textAlign='right'>
                      {t('enrollment.editor.fields.towerSilos.updatedAt')}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {towerSiloRows.map((row, index) => {
                    const updatedAt = enrollment.farm.towerSilos[index]
                      ? new Date(enrollment.farm.towerSilos[index].updatedAt)
                      : new Date();
                    return (
                      <Tr key={row.id}>
                        <Td>
                          <Text>{row.number}</Text>
                        </Td>
                        <Td>
                          <Checkbox size='lg' colorScheme='blue' {...register(`towerSilos.${index}.isActive`)} />
                        </Td>
                        <Td>
                          <Input
                            textAlign='right'
                            {...register(`towerSilos.${index}.diameter`, { valueAsNumber: true })}
                          />
                        </Td>
                        <Td>
                          <Input
                            textAlign='right'
                            {...register(`towerSilos.${index}.height`, { valueAsNumber: true })}
                          />
                        </Td>
                        <Td>
                          <Text textAlign='right'>{dateTimeFormat.format(updatedAt)}</Text>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              <HStack px='1rem' pt='0.5rem' pb='1rem'>
                <Button
                  variant='solid'
                  size='xs'
                  leftIcon={<AddIcon />}
                  colorScheme='blue'
                  onClick={() =>
                    appendTowerSilo({ number: towerSiloRows.length + 1, isActive: true, diameter: 0, height: 0 })
                  }
                >
                  {t('enrollment.editor.fields.towerSilos.add')}
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </Box>
      </Container>
      <Fade in={isDirty}>
        <Box bottom='0' position='fixed' left='0' w='100%' py={10} px={14} bg='bg.surface'>
          <Flex>
            <Spacer />
            <HStack spacing={5}>
              <Button variant='tertiary' onClick={() => setLoaded(false)}>
                {t('enrollment.editor.actions.cancel')}
              </Button>
              <Button variant='solid' colorScheme='blue' type='submit' isLoading={isSubmitting} isDisabled={!isValid}>
                {t('enrollment.editor.actions.save')}
              </Button>
              <Button
                variant='solid'
                colorScheme='blue'
                type='submit'
                isLoading={isSubmitting}
                isDisabled={!isValid}
                onClick={() => setCloseOnSuccess(true)}
              >
                {t('enrollment.editor.actions.saveAndClose')}
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Fade>
    </form>
  );
};
