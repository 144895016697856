import { createContext, type FC, type ReactNode, useContext } from 'react';

import { useFindUserByFirebaseUidQuery, type UserFieldsFragment } from '../graphql/hooks';

import { useFirebaseAuth } from './FirebaseAuthContext';

const UserContext = createContext<UserFieldsFragment | null | undefined>(undefined);

const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const authUser = useFirebaseAuth();
  const { data } = useFindUserByFirebaseUidQuery(
    { firebaseUid: authUser?.uid || '' },
    { enabled: !!authUser, refetchOnWindowFocus: false, staleTime: 24 * 3600 * 1000 }
  );

  const user = data?.userByFirebaseUid || null;

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

function useUser() {
  const user = useContext(UserContext);
  if (user === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return user;
}

export { UserProvider, useUser };
