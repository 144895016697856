import '@fontsource-variable/inter';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { FirebaseAuthProvider } from '../contexts/FirebaseAuthContext';
import { FirebaseProvider } from '../contexts/FirebaseContext';
import { FirebaseRemoteConfigProvider } from '../contexts/FirebaseRemoteConfigContext';
import { AppRoutes } from '../routes';
import { brandTheme } from '../theme';

export const App = () => (
  <React.StrictMode>
    <QueryClientProvider client={new QueryClient()}>
      <FirebaseProvider>
        <FirebaseRemoteConfigProvider>
          <FirebaseAuthProvider>
            <BrowserRouter>
              <ChakraProvider theme={brandTheme}>
                <AppRoutes />
              </ChakraProvider>
            </BrowserRouter>
          </FirebaseAuthProvider>
        </FirebaseRemoteConfigProvider>
      </FirebaseProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
