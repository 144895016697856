// Shamelessly borrowed (and adapted) from https://github.com/chakra-ui/chakra-ui/issues/580#issuecomment-703261215
import SourceDatePicker, { type ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { Box } from '@chakra-ui/react';
import * as locales from 'date-fns/locale';

import { datePickerStyles } from './datePickerStyles';

import 'react-datepicker/dist/react-datepicker.css';

export const DatePicker: React.FC<ReactDatePickerProps> = (props) => {
  const { i18n } = useTranslation();
  const locale = Object.values(locales).find((locale) => locale.code === i18n.language) || locales.frCA;
  registerLocale(i18n.language, locale);

  return (
    <Box sx={datePickerStyles}>
      <SourceDatePicker {...props} locale={locale} />
    </Box>
  );
};
