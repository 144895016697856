import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import enResources from './locales/en_CA/translation';
import frResources from './locales/fr_CA/translation';

i18n.use(initReactI18next).init({
  resources: {
    'fr-CA': {
      translation: frResources,
    },
    'en-CA': {
      translation: enResources,
    },
  },
  lng: 'fr-CA',
  fallbackLng: 'fr-CA',
  debug: process.env.NODE_ENV !== 'production',
  interpolation: {
    escapeValue: false,
  },
});

// eslint-disable-next-line no-restricted-exports
export { default } from 'i18next';
