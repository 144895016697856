export const datePickerStyles = {
  '.react-datepicker': {
    fontFamily: 'body',
    fontSize: '0.9rem',
  },

  '.react-datepicker-wrapper, .react-datepicker__input-container': {
    display: 'block',
  },

  '.react-datepicker__input-container': {
    fontSize: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '2.5rem',
    borderRadius: '0.25rem',
    border: '1px solid',
    borderColor: 'brand.200',
  },

  '.react-datepicker__input-container:hover': {
    borderColor: 'brand.300',
  },

  '.react-datepicker__input-container:focus-within': {
    zIndex: '1',
    borderColor: 'brand.500',
    boxShadow: '0 0 0 1px #3182ce',
  },

  '.react-datepicker__input-container > input': {
    width: '100%',
    height: '100%',
    outline: '0',
  },

  '.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)': {
    right: '90px',
  },

  '.react-datepicker__navigation--previous, .react-datepicker__navigation--next': {
    height: '40px',
  },

  '.react-datepicker__header': {
    background: 'gray.100',
  },

  '.react-datepicker__header, .react-datepicker__time-container': {
    borderColor: 'gray.500',
  },

  '  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header': {
    fontSize: 'inherit',
    fontWeight: '600',
  },

  '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item':
    {
      margin: '0 1px 0 0',
      height: 'auto',
      padding: '7px 10px',
    },

  '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover':
    {
      background: 'gray.200',
    },

  '.react-datepicker__day:hover': {
    background: 'gray.200',
  },

  '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
    {
      background: 'blue.500',
      fontWeight: 'normal',
    },

  '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover':
    {
      background: 'gray.200',
    },

  '.react-datepicker__close-icon::after': {
    backgroundColor: 'unset',
    borderRadius: 'unset',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'gray.600',
    height: '20px',
    width: '20px',
  },

  '.react-datepicker__close-icon::after:hover': {
    color: 'gray.500',
  },
};
