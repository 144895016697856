import type * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, Link, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react';

import { useRemoteConfig } from '../../contexts/FirebaseRemoteConfigContext';
import { CultureLifecycle } from '../../graphql/hooks';

import { EnrollmentEditor } from './EnrollmentEditor';
import { EnrollmentMowings } from './EnrollmentMowings';
import { NotePage } from './NotePage';

export const EnrollmentEditPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>() as { id: string };
  const state = useLocation().state as { previousLocation?: Location };
  const onClose = state?.previousLocation ? () => navigate(-1) : () => navigate('/enrollment');

  const { enrollmentEditorWithTabs } = useRemoteConfig();
  const [title, setTitle] = useState<string>('');
  const [isDirty, setDirty] = useState<boolean>(false);

  if (enrollmentEditorWithTabs) {
    return (
      <Box minH='100vh' bgColor='gray.500'>
        <Tabs isLazy>
          <Box bgColor='white' mt={{ base: -3, md: -16 }}>
            <Container pt='20px'>
              <VStack spacing='20px' align='left'>
                <Link onClick={onClose}>
                  <ChevronLeftIcon />
                  {t('enrollment.editor.actions.back')}
                </Link>
                <Heading as='h1' fontSize='30px' lineHeight='36px'>
                  {title}
                </Heading>
                <TabList>
                  <Tab isDisabled={isDirty}>{t('enrollment.editor.tabs.profile')}</Tab>
                  <Tab isDisabled={isDirty}>{t('enrollment.editor.tabs.perennial')}</Tab>
                  <Tab isDisabled={isDirty}>{t('enrollment.editor.tabs.annual')}</Tab>
                  <Tab isDisabled={isDirty}>{t('enrollment.editor.tabs.notes')}</Tab>
                </TabList>
              </VStack>
            </Container>
          </Box>
          <TabPanels pt={8}>
            <TabPanel>
              <EnrollmentEditor id={id} onClose={onClose} setTitle={setTitle} setDirty={setDirty} />
            </TabPanel>
            <TabPanel>
              <EnrollmentMowings
                id={Number(id)}
                cultureLifecycle={CultureLifecycle.Perennial}
                onClose={onClose}
                setDirty={setDirty}
              />
            </TabPanel>
            <TabPanel>
              <EnrollmentMowings
                id={Number(id)}
                cultureLifecycle={CultureLifecycle.Annual}
                onClose={onClose}
                setDirty={setDirty}
              />
            </TabPanel>
            <TabPanel>
              <NotePage id={id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
  }
  return (
    <>
      <Container mt={-8} mb={8}>
        <Link onClick={onClose}>
          <ChevronLeftIcon />
          {t('enrollment.editor.actions.back')}
        </Link>
      </Container>
      <EnrollmentEditor id={id} onClose={onClose} setTitle={setTitle} setDirty={setDirty} />
    </>
  );
};
