import { type FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiShieldCheckFill, RiShieldUserFill } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { type Column, type Row, usePagination, useSortBy, useTable } from 'react-table';

import { ArrowDownIcon, ArrowUpIcon, ChatIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  HStack,
  Icon,
  Progress,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { keepPreviousData } from '@tanstack/react-query';
import { useDidUpdate, useSessionstorageState } from 'rooks';

import { useUser } from '../../contexts/UserContext';
import {
  MowingStatus,
  type ParticipantListFieldsFragment,
  ParticipantSortField,
  type ParticipantSortInput,
  ParticipantType,
  type RegionType,
  SortOrder,
  useParticipantsForListQuery,
} from '../../graphql/hooks';
import { EnrollmentLastNote } from '../Enrollment/EnrollmentLastNote';
import { MowingStatusBadge } from '../Mowing/MowingStatusBadge';

type ParticipantListProps = {
  season: number | null;
  mowingNumber: number | null;
  regionType: RegionType | null;
  expectedMowing: boolean;
  hasApp: boolean | null;
  mowingStatus: MowingStatus | null;
  search: string | null;
};

export const ParticipantTable: FC<ParticipantListProps> = ({
  season,
  search,
  mowingNumber,
  regionType,
  expectedMowing,
  hasApp,
  mowingStatus,
}) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'participant.table' });
  const navigate = useNavigate();
  const location = useLocation();
  const dateTimeFormat = useMemo(() => new Intl.DateTimeFormat(i18n.language), [i18n.language]);
  const user = useUser();

  const [selectedRowId, setSelectedRowId] = useSessionstorageState<string | null>('participants.selectedRowId', null);
  const [querySort, setQuerySort] = useSessionstorageState<ParticipantSortInput[]>('participants.sort', [
    { field: ParticipantSortField.Number, order: SortOrder.Asc },
  ]);
  const [queryPageIndex, setQueryPageIndex] = useSessionstorageState('participants.page', 0);
  const pageSize = 50;

  const mowingQuery = useParticipantsForListQuery(
    {
      seasonId: season || 0,
      sponsorId: user?.sponsor?.id || '',
      mowingNumber: mowingNumber || 0,
      regionType,
      expectedMowing,
      hasApp,
      mowingStatus,
      search,
      pageIndex: queryPageIndex,
      sort: querySort,
    },
    {
      enabled: season !== null && mowingNumber !== null,
      staleTime: 5 * 60 * 1000,
      placeholderData: keepPreviousData,
    }
  );
  const mowings = useMemo(() => mowingQuery.data?.participants || [], [mowingQuery]);
  const mowingCount = useMemo(() => mowingQuery.data?.participantCount || 0, [mowingQuery]);

  const defaultColumn: Partial<Column<ParticipantListFieldsFragment>> = useMemo(
    () => ({
      width: '0.1%',
    }),
    []
  );

  const participantTypeIcon: { [key: string]: JSX.Element } = {
    [ParticipantType.Selfserve]: <span />,
    [ParticipantType.Volunteered]: <Icon as={RiShieldUserFill} color='blue.400' boxSize={18} />,
    [ParticipantType.Sponsored]: <Icon as={RiShieldCheckFill} color='green.500' boxSize={18} />,
  };

  const columns: Array<Column<ParticipantListFieldsFragment>> = useMemo(
    () => [
      {
        id: ParticipantSortField.Number,
        accessor: (participant) => participant,
        Header: t('headers.number'),
        Cell: ({ value }: { value: ParticipantListFieldsFragment }) => (
          <HStack justify='space-between'>
            <Text>{value.enrollment.farm.number || ''}</Text>
            {participantTypeIcon[value.type]}
          </HStack>
        ),
      },
      {
        id: ParticipantSortField.App,
        accessor: (participant) => t(`values.hasUsers.${participant.hasUsers}`),
        Header: t('headers.appInstalled'),
        sortDescFirst: true,
      },
      {
        id: ParticipantSortField.Name,
        accessor: ({ enrollment: { farm } }) =>
          `${farm.accountName} || ${farm.customerFirstName || ''} ${farm.customerLastName || ''}`.trim(),
        Header: t('headers.farmName'),
        width: '100%',
        Cell: ({ value }: { value: string }) => {
          const [account, customer] = value.split('||');
          return (
            <VStack align='start' spacing='0'>
              <Text>{account.trim()}</Text>
              <Text color='gray.500'>{customer.trim()}&nbsp;</Text>
            </VStack>
          );
        },
      },
      {
        id: ParticipantSortField.RegionType,
        accessor: ({ enrollment: { farm } }) =>
          t(`values.regionType.${farm.station?.regionType.toLowerCase() as 'central' | 'regional' | 'unknown'}`),
        Header: t('headers.regionType'),
      },
      {
        id: ParticipantSortField.ExpectedMowings,
        accessor: ({ enrollment }) => enrollment.expectedMowings,
        Header: t('headers.expectedMowings'),
      },
      {
        id: ParticipantSortField.MowingPercentComplete,
        accessor: ({ mowing }) => mowing?.percentComplete ?? 0,
        Header: t('headers.percentComplete'),
        Cell: ({ value }: { value: number }) => <span>{`${value}%`}</span>,
        sortDescFirst: true,
      },
      {
        id: ParticipantSortField.MowingStatus,
        accessor: ({ mowing }) => mowing?.status ?? MowingStatus.Call,
        Header: t('headers.status'),
        Cell: ({ value }: { value: MowingStatus }) => <MowingStatusBadge status={value} />,
      },
      {
        id: ParticipantSortField.UpdatedAt,
        accessor: 'updatedAt',
        Header: t('headers.updatedAt'),
        Cell: ({ value }: { value: string }) => <span>{dateTimeFormat.format(new Date(value))}</span>,
        sortDescFirst: true,
      },
      {
        id: 'note',
        accessor: 'enrollment',
        Header: t('headers.note'),
        Cell: (cell) =>
          cell.value.noteCount > 0 && (
            <Tooltip
              label={<EnrollmentLastNote enrollmentId={cell.row.original.enrollment.id} />}
              placement='bottom-end'
            >
              <ChatIcon />
            </Tooltip>
          ),
        disableSortBy: true,
      },
    ],
    [t, dateTimeFormat]
  ) /* FIXME: Type Hack! */ as Array<Column<ParticipantListFieldsFragment>>;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data: mowings,
      defaultColumn,
      autoResetSortBy: false,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: Math.ceil(mowingCount / pageSize),
      initialState: {
        sortBy: querySort.map(({ field, order }) => ({
          id: field,
          desc: order === SortOrder.Desc,
        })),
        pageIndex: queryPageIndex,
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => setQueryPageIndex(pageIndex), [pageIndex, setQueryPageIndex]);

  useEffect(() => {
    setQuerySort(
      sortBy.map(({ id, desc }) => ({
        field: id as ParticipantSortField,
        order: desc ? SortOrder.Desc : SortOrder.Asc,
      }))
    );
  }, [setQuerySort, sortBy]);

  useDidUpdate(() => gotoPage(0), [gotoPage, season, mowingNumber, regionType, search, sortBy]);

  const handleRowClick = (row: Row<ParticipantListFieldsFragment>) => {
    setSelectedRowId(row.original.id);
    navigate(`/enrollment/edit/${row.original.enrollment.id}`, {
      state: { previousLocation: location },
    });
  };

  return (
    <Box>
      {mowingQuery.isFetching ? <Progress size='xs' colorScheme='green' isIndeterminate /> : <Spacer height={1} />}
      <Stack spacing='5'>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ style: { width: column.width, userSelect: 'none' } })
                    )}
                  >
                    {column.render('Header')}
                    <chakra.span pl='4'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownIcon aria-label='sorted descending' />
                        ) : (
                          <ArrowUpIcon aria-label='sorted ascending' />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  verticalAlign='top'
                  {...(selectedRowId === row.original.id && { bg: 'cecpaGreen.50' })}
                  _hover={{ background: 'gray.200' }}
                  cursor='pointer'
                  onClick={() => handleRowClick(row)}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps({ style: { width: cell.column.width, minWidth: cell.column.minWidth } })}>
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Box px={{ base: '4', md: '6' }} pb='5'>
          <HStack spacing='3' justify='space-between'>
            {!mowingQuery.isLoading && (
              <>
                <Text color='fg.muted' fontSize='sm'>
                  {t('pagination.results', {
                    lower: Math.min(pageSize * pageIndex + 1, mowingCount),
                    upper: Math.min(pageSize * (pageIndex + 1), mowingCount),
                    total: mowingCount,
                  })}
                </Text>
                <ButtonGroup
                  spacing='3'
                  justifyContent='space-between'
                  width={{ base: 'full', md: 'auto' }}
                  variant='secondary'
                >
                  <Button isDisabled={!canPreviousPage} onClick={() => previousPage()}>
                    {t('pagination.previous')}
                  </Button>
                  <Button isDisabled={!canNextPage} onClick={() => nextPage()}>
                    {t('pagination.next')}
                  </Button>
                </ButtonGroup>
              </>
            )}
          </HStack>
        </Box>
      </Stack>
    </Box>
  );
};
