import { GraphQLClient, type Variables } from 'graphql-request';

import { useFirebaseAuth } from '../contexts/FirebaseAuthContext';

// See https://www.graphql-code-generator.com/plugins/typescript-react-query#usage-example-isreacthook-true
export const useAuthorizedGraphQLClient = <TData, TVariables extends Variables = Variables>(
  query: string,
  requestHeaders?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const user = useFirebaseAuth();
  return async (variables?: TVariables): Promise<TData> => {
    const client = new GraphQLClient('/api/graphql');
    if (user) {
      const token = await user.getIdToken();
      client.setHeader('Authorization', `Bearer ${token}`);
    }
    return client.request(query, variables, requestHeaders);
  };
};
