import { Navigate, Route, Routes } from 'react-router-dom';

import { EnrollmentEditPage, EnrollmentListPage } from '../components/Enrollment';
import { EnrollmentReportPage } from '../components/EnrollmentReport';
import { Layout } from '../components/Layout';
import Login from '../components/Login';
import Logout from '../components/Logout';
import { ParticipantListPage } from '../components/Participant';
import Reports from '../components/Reports';
import { YieldAverageListPage } from '../components/YieldAverage/YieldAverageListPage';

import RequireAuth from './RequireAuth';

export const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<Layout />}>
      <Route index element={<Navigate to='/enrollment' replace />} />
      <Route
        path='/enrollment'
        element={
          <RequireAuth>
            <EnrollmentListPage />
          </RequireAuth>
        }
      />
      <Route
        path='/participants'
        element={
          <RequireAuth>
            <ParticipantListPage />
          </RequireAuth>
        }
      />
      <Route
        path='/enrollment/edit/:id'
        element={
          <RequireAuth>
            <EnrollmentEditPage />
          </RequireAuth>
        }
      />
      <Route
        path='/reports'
        element={
          <RequireAuth>
            <Reports />
          </RequireAuth>
        }
      />
      <Route
        path='/yieldAverage'
        element={
          <RequireAuth>
            <YieldAverageListPage />
          </RequireAuth>
        }
      />
      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
    </Route>
    <Route path='/enrollmentReport/:cipher' element={<EnrollmentReportPage />} />
  </Routes>
);
