import type * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Grid, GridItem, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import type { TFunction } from 'i18next';

import {
  type CultureLifecycle,
  type HarvestBales,
  HarvestType,
  type MowingForReportFragment,
  type TowerSiloForReportFragment,
} from '../../graphql/hooks';
import type { AnyHarvest } from '../Harvest/HarvestEditForm';

import styles from './EnrollmentReport.module.css';

export type EnrollmentReportMowingProps = {
  cultureLifecycle: CultureLifecycle;
  mowings: Array<MowingForReportFragment>;
  towerSilos: Array<TowerSiloForReportFragment>;
};

function hasBales(harvest: unknown): harvest is HarvestBales {
  return (harvest as HarvestBales).bales !== undefined;
}

export const EnrollmentReportMowings: React.FC<EnrollmentReportMowingProps> = ({
  cultureLifecycle,
  mowings,
  towerSilos,
}) => {
  const { t, i18n } = useTranslation();
  const dateTimeFormat = useMemo(() => new Intl.DateTimeFormat(i18n.language), [i18n.language]);
  const numberFormat = useMemo(() => new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 0 }), [i18n]);

  return (
    <Box pb='28px' className={styles['page-break']}>
      <Grid templateColumns='repeat(8, 1fr)' gap='12px' mb='16px'>
        <GridItem colSpan={6}>
          <Flex alignItems='baseline'>
            <Text fontSize='4xl' casing='uppercase' color='cecpaGreen.500'>
              {t(
                `enrollment.report.cultureLifecycle.header.${cultureLifecycle.toLowerCase() as 'annual' | 'perennial'}`
              )}
            </Text>
            <Text fontSize='2xl' ml='5'>
              {t('enrollment.report.cultureLifecycle.header.title')}
            </Text>
          </Flex>
        </GridItem>
      </Grid>
      {mowings.map((mowing) => (
        <Box key={mowing.id} mb='28px' className={styles['page-break']}>
          <Table
            bgColor='gray.100'
            className={styles['header-table']}
            sx={{ tableLayout: 'fixed' }}
            whiteSpace='normal'
          >
            <Tbody>
              <Tr>
                <Td>
                  <Text ml='12px' fontSize='medium' fontWeight={700}>
                    {t('enrollment.report.mowing.header.mowing', { number: mowing.number })}
                  </Text>
                </Td>
                <Td>
                  <Flex>
                    <Text fontWeight={700}>{t('enrollment.report.mowing.header.start')}</Text>
                    <Text ml='5px'>{dateTimeFormat.format(new Date(mowing.startsAt))}</Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    <Text fontWeight={700}>{t('enrollment.report.mowing.header.end')}</Text>
                    <Text ml='5px'>{dateTimeFormat.format(new Date(mowing.endsAt))}</Text>
                  </Flex>
                </Td>
                <Td colSpan={2}>
                  <Flex>
                    <Text>{t('enrollment.report.mowing.header.yield')}</Text>
                    <Text fontWeight={700} ml='5px'>
                      {numberFormat.format(mowing.yieldPerHectare)}
                    </Text>
                  </Flex>
                </Td>
                <Td />
                <Td />
                <Td />
              </Tr>
            </Tbody>
          </Table>

          <Table bgColor='transparent' className={styles.table} sx={{ tableLayout: 'fixed' }} whiteSpace='normal'>
            <Tbody>
              <Tr>
                <Td width='12.5%' verticalAlign='top' rowSpan={mowing.harvests.length + 2}>
                  <Text ml='12px'>{t('enrollment.report.mowing.header.hectares')}</Text>
                  <Text ml='12px' fontWeight={700}>
                    {numberFormat.format(mowing.harvestedHectares ?? 0)}
                  </Text>
                </Td>
                <Td width='25%' colSpan={2}>
                  <Text fontWeight={700}>{t('enrollment.report.mowing.header.harvestType')}</Text>
                </Td>
                <Td width='25%' colSpan={2}>
                  <Text fontWeight={700}>{t('enrollment.report.mowing.header.dimensions')}</Text>
                </Td>
                <Td width='12.5%'>
                  <Text fontWeight={700}>{t('enrollment.report.mowing.header.units')}</Text>
                </Td>
                <Td width='12.5%'>
                  <Text fontWeight={700}>{t('enrollment.report.mowing.header.weight')}</Text>
                </Td>
                <Td width='12.5%'>
                  <Text fontWeight={700}>{t('enrollment.report.mowing.header.totalWeight')}</Text>
                </Td>
              </Tr>
              {mowing.harvests.map((harvest) => (
                <Tr key={harvest.id}>
                  <Td colSpan={2}>{t(`harvestTypes.${harvest.harvestType}`)}</Td>
                  <Td colSpan={2}>{getHarvestDimensions(harvest as AnyHarvest, towerSilos, t)}</Td>
                  <Td>{hasBales(harvest) ? numberFormat.format(harvest.bales) : '-'}</Td>
                  <Td>{harvest.baleWeight ? numberFormat.format(harvest.baleWeight) : '-'}</Td>
                  <Td>{harvest.calculatedYield ? numberFormat.format(harvest.calculatedYield) : '-'}</Td>
                </Tr>
              ))}
              <Tr>
                <Td colSpan={6} />
                <Td>
                  <Text fontWeight={700}>
                    {numberFormat.format(
                      mowing.harvests.reduce((total, harvest) => total + (harvest.calculatedYield ?? 0), 0)
                    )}
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      ))}
    </Box>
  );
};

// Create virtual properties like 'width' that converts to it's widthFeet'widthInches" string representation
const sizeProxyHandler = {
  get(target: Record<string, unknown>, prop: string) {
    if (`${prop}Feet` in target) {
      const feet = target[`${prop}Feet`];
      const inches = target[`${prop}Inches`];
      const feetSegment = feet ? `${feet as number}'` : '';
      const inchesSegment = inches ? `${inches as number}"` : '';
      return feetSegment + inchesSegment;
    }
    return target[prop];
  },
  ownKeys(target: Record<string, unknown>) {
    const keys = Reflect.ownKeys(target);
    keys.forEach((key) => {
      if (key.toString().endsWith('Feet')) {
        keys.push(key.toString().slice(0, key.toString().length - 4));
      }
    });
    return keys;
  },
  getOwnPropertyDescriptor(target: Record<string, unknown>, prop: string) {
    return {
      value: target[prop],
      enumerable: true,
      configurable: true,
      writable: true,
    };
  },
};

const getHarvestDimensions = (
  harvest: AnyHarvest,
  towerSilos: Array<TowerSiloForReportFragment>,
  t: TFunction<'translation', undefined>
) => {
  const interpolation =
    harvest.harvestType === HarvestType.TowerSiloHarvest
      ? towerSilos.find((towerSilo) => towerSilo.number === harvest.siloNumber)
      : new Proxy(harvest, sizeProxyHandler);
  return t(`enrollment.report.harvest.${harvest.harvestType}`, interpolation);
};
