import type * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormLabel, Input } from '@chakra-ui/react';

import { FormRow } from '../../Form';

export const SmallSquareBaleHarvestForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'enrollment.editor.harvests.form' });
  const { register } = useFormContext();

  return (
    <FormRow templateColumns='15rem' spacing='0'>
      <FormLabel htmlFor='smallSquareBaleHarvest.bales'>{t('bales')}</FormLabel>
      <Input
        id='smallSquareBaleHarvest.bales'
        textAlign='right'
        {...register('smallSquareBaleHarvest.bales', { valueAsNumber: true, required: true })}
      />
    </FormRow>
  );
};
