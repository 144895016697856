import type * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { FormRow } from '../../Form';

export const SilagePileHarvestForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'enrollment.editor.harvests.form' });
  const { register } = useFormContext();

  return (
    <>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='silagePileHarvest.widthFeet'>{t('widthFeet')}</FormLabel>
          <Input
            id='silagePileHarvest.widthFeet'
            textAlign='right'
            {...register('silagePileHarvest.widthFeet', {
              valueAsNumber: true,
              required: true,
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='silagePileHarvest.lengthFeet'>{t('lengthFeet')}</FormLabel>
          <Input
            id='silagePileHarvest.lengthFeet'
            textAlign='right'
            {...register('silagePileHarvest.lengthFeet', {
              valueAsNumber: true,
              required: true,
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormControl>
          <FormLabel htmlFor='silagePileHarvest.heightFeet'>{t('heightFeet')}</FormLabel>
          <Input
            id='silagePileHarvest.heightFeet'
            textAlign='right'
            {...register('silagePileHarvest.heightFeet', {
              valueAsNumber: true,
              required: true,
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormLabel htmlFor='silagePileHarvest.moistureLevel'>{t('moistureLevel')}</FormLabel>
        <Input
          id='silagePileHarvest.moistureLevel'
          textAlign='right'
          {...register('silagePileHarvest.moistureLevel', { valueAsNumber: true })}
        />
      </FormRow>
    </>
  );
};
