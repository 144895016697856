import type * as React from 'react';

import { HStack, Text } from '@chakra-ui/react';

export type FormHeaderProps = {
  text: string;
  dropdown?: React.ReactElement | false;
  icon?: React.ReactElement;
  spacing?: string | number;
  align?: string;
};

export const FormHeader: React.FC<FormHeaderProps> = ({ text, dropdown, icon, spacing, align }) => (
  <HStack p='6' pb='3' justifyContent='space-between'>
    <HStack spacing={spacing || '24px '} {...(align && { align })}>
      <Text fontSize='lg' fontWeight='bold'>
        {text}
      </Text>
      (dropdown && {dropdown})
    </HStack>
    (icon && {icon})
  </HStack>
);
