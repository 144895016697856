import type * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { FormRow } from '../../Form';
import { ChamberTypeSelect } from '../Widgets/ChamberTypeSelect';
import { RotoCutSelect } from '../Widgets/RotoCutSelect';

import { eitherRequired } from './Validation';

export const MoistRoundBaleHarvestForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'enrollment.editor.harvests.form' });
  const { control, register, getValues } = useFormContext();

  return (
    <>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='moistRoundBaleHarvest.chamberType'>{t('chamberType')}</FormLabel>
          <Controller
            name='moistRoundBaleHarvest.chamberType'
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => <ChamberTypeSelect value={value} onChange={onChange} />}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='moistRoundBaleHarvest.rotoCut'>{t('rotoCut')}</FormLabel>
          <Controller
            name='moistRoundBaleHarvest.rotoCut'
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => <RotoCutSelect value={value} onChange={onChange} />}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='moistRoundBaleHarvest.widthFeet'>{t('widthFeet')}</FormLabel>
          <Input
            id='moistRoundBaleHarvest.widthFeet'
            textAlign='right'
            {...register('moistRoundBaleHarvest.widthFeet', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('moistRoundBaleHarvest.widthFeet'),
                  getValues('moistRoundBaleHarvest.widthInches')
                ),
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='moistRoundBaleHarvest.widthInches'>{t('widthInches')}</FormLabel>
          <Input
            id='moistRoundBaleHarvest.widthInches'
            textAlign='right'
            {...register('moistRoundBaleHarvest.widthInches', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('moistRoundBaleHarvest.widthFeet'),
                  getValues('moistRoundBaleHarvest.widthInches')
                ),
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='moistRoundBaleHarvest.diameterFeet'>{t('diameterFeet')}</FormLabel>
          <Input
            id='moistRoundBaleHarvest.diameterFeet'
            textAlign='right'
            {...register('moistRoundBaleHarvest.diameterFeet', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('moistRoundBaleHarvest.diameterFeet'),
                  getValues('moistRoundBaleHarvest.diameterInches')
                ),
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='moistRoundBaleHarvest.diameterInches'>{t('diameterInches')}</FormLabel>
          <Input
            id='moistRoundBaleHarvest.diameterInches'
            textAlign='right'
            {...register('moistRoundBaleHarvest.diameterInches', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('moistRoundBaleHarvest.diameterFeet'),
                  getValues('moistRoundBaleHarvest.diameterInches')
                ),
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormLabel htmlFor='moistRoundBaleHarvest.bales'>{t('bales')}</FormLabel>
        <Input
          id='moistRoundBaleHarvest.bales'
          textAlign='right'
          {...register('moistRoundBaleHarvest.bales', { valueAsNumber: true, required: true })}
        />
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormLabel htmlFor='moistRoundBaleHarvest.moistureLevel'>{t('moistureLevel')}</FormLabel>
        <Input
          id='moistRoundBaleHarvest.moistureLevel'
          textAlign='right'
          {...register('moistRoundBaleHarvest.moistureLevel', { valueAsNumber: true })}
        />
      </FormRow>
    </>
  );
};
