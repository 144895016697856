export default {
  loading: 'Chargement...',
  error: 'Une erreur est survenue.',
  nav: {
    enrollment: 'Recrutement',
    participants: 'Participants',
    reports: 'Rapports',
    yieldAverage: 'Moyennes',
  },
  harvestTypes: {
    SmallSquareBaleHarvest: 'Petites balles rectangulaires',
    LargeMoistSquareBaleHarvest: 'Grosses balles rectangulaires humides',
    LargeDrySquareBaleHarvest: 'Grosses balles rectangulaires sèches',
    MoistRoundBaleHarvest: 'Balles rondes humides',
    DryRoundBaleHarvest: 'Balles rondes sèches',
    TowerSiloHarvest: 'Silo-tour non-hermétique',
    BunkerSiloHarvest: 'Fosse',
    SilagePileHarvest: 'Meule',
    BagHarvest: "Boudin (sac d'ensilage)",
    LoadHarvest: 'Voyage',
  },
  components: {
    select: {
      placeholder: 'Choisir...',
    },
    BooleanSelect: {
      options: {
        true: 'Oui',
        false: 'Non',
      },
    },
    LanguageSelect: {
      F: 'Français',
      A: 'Anglais',
    },
    MowingNumberSelect: {
      label: 'Fauche {{number}}',
    },
    TowerSiloSelect: {
      label: 'Silo tour {{number}}: {{diameter}} x {{height}} pieds',
    },
    RegionTypeSelect: {
      values: {
        central: 'Centrales',
        regional: 'Périphériques',
      },
      placeholder: 'Toutes les régions',
    },
    ConfirmationDialog: {
      confirm: 'Confirmer',
      cancel: 'Annuler',
    },
    AppFilterSelect: {
      placeholder: 'Application',
    },
    PhoneNumberInput: {
      placeholder: 'numéro de téléphone',
    },
    useValidatePhoneNumberIsAvailable: {
      unavailable: 'ce numéro de téléphone est déjà utilisé par {{farmName}}',
    },
  },
  enrollment: {
    status: {
      select: {
        placeholder: 'Tous les statuts',
      },
      enum: {
        TODO: 'À faire',
        PENDING: 'En attente',
        ACCEPTED: 'Participe',
        REFUSED: 'Ne participe pas',
        UNREACHABLE: 'Non joint',
        COMPLETED: 'Complété',
        WITHDRAWN: 'Abandon',
        VOLUNTEERED: 'Volontaire',
      },
    },
    station: {
      select: {
        placeholder: 'Toutes les stations',
      },
    },
    table: {
      headers: {
        selectionOrder: 'SEL.',
        farmNumber: 'CLINUM',
        status: 'STATUT',
        farmStation: 'STATION',
        farmName: 'NOM',
        updatedAt: 'MODIFIÉ LE',
        note: 'NOTE',
      },
      pagination: {
        results: 'Entreprises {{lower}} à {{upper}} sur total de {{total}}',
        previous: 'Précédente',
        next: 'Suivante',
      },
    },
    editor: {
      headers: {
        business: 'Entreprise',
        application: 'Application mobile',
        communication: 'Communications',
        towerSilos: 'Silos tours - Non hermétiques',
        parcels: 'Liste des champs',
      },
      dividers: {
        corresponding: 'CORRESPONDANCE',
        operations: 'EXPLOITATION',
        comments: "REMARQUES SUR L'ENTREPRISE",
        users: 'UTILISATEURS',
      },
      tabs: {
        profile: 'Dossier client',
        perennial: 'Fourrage vivace',
        annual: 'Fourrage annuel',
        notes: 'Notes',
      },
      actions: {
        save: 'Sauvegarder',
        saveAndClose: 'Sauvegarder et fermer',
        cancel: 'Annuler',
        back: 'Retour à la liste',
      },
      help: {
        comments: 'Les remarques ne sont pas visibles par le producteur.',
        notes: 'Les notes ne sont pas visibles par le producteur.',
      },
      fields: {
        accountName: "Nom de l'entreprise",
        hectares: 'Superficie (ha.)',
        activationCode: 'Mot-de-passe app.',
        activationCodeExpirationDate: 'Expire le {{date}}',
        annualCulture: 'Fourrage annuel?',
        annualCultureHectares: 'Superficie fourrage an. (ha.)',
        saleIntent: 'Vente de foin?',
        salePrice: 'Prix/tonne',
        nextSeasonInterest: 'Intérêt pour la prochaine saison?',
        city: 'Municipalité',
        cityRequired: 'ce champ est requis',
        comments: 'Remarques (600 car. maximum)',
        contactPreference: {
          label: 'Préférence de communication',
          placeholder: 'Téléphone, courriel...',
        },
        customerFirstName: 'Prénom',
        customerLastName: 'Nom',
        email: 'Courriel',
        expectedMowings: 'Fauches prévues',
        farmNumber: 'CLINUM',
        farmMinistryNumber: 'NIM',
        hasInterest: "Intérêt pour l'app.",
        language: 'Langue',
        mailAddressStreet: 'Adresse de correspondance',
        mobilePhone: 'Mobile',
        operationAddressStreet: "Adresse de l'exploitation",
        operationPhone: 'Téléphone',
        postalCode: 'Code Postal',
        primaryPhone: 'Téléphone 1',
        region: 'Région administrative',
        reportUrl: 'Lien vers le bilan',
        secondaryPhone: 'Téléphone 2',
        selectionOrder: 'Ordre de sélection',
        serviceCenter: 'Centre de services',
        station: 'Station',
        status: 'Statut de recrutement',
        addParcels: {
          label: 'Ajouter un ou plusieurs champs, séparés par des virgules',
          placeholder: '1, 2, 3...',
          add: 'Ajouter à la liste',
        },
        towerSilos: {
          number: '#',
          isActive: 'ACTIF',
          diameter: 'DIAMÈTRE (pieds)',
          height: 'HAUTEUR (pieds)',
          updatedAt: 'MODIFIÉ LE',
          add: 'Ajouter',
        },
        users: {
          phoneNumber: 'téléphone',
          firstName: 'prénom',
          lastName: 'nom',
          lastConnection: 'dernière connexion',
          add: 'Ajouter',
          remove: 'Supprimer',
        },
      },
      mowings: {
        add: 'Nouvelle fauche',
        delete: 'Supprimer',
        expected: 'Fauches prévues',
        title: 'Fauche {{number}}',
        startsAt: 'Début',
        endsAt: 'Fin',
        hectares: 'Superficie récoltée',
        yieldPerHectare: 'Rendement',
        publishedYieldAverage: 'Moyenne régionale Collecto',
        yieldUnit: 'kg/ha',
        percentComplete: 'Progression de la fauche',
        harvestedHectaresUnit: 'ha',
        weightUnit: 'kg',
      },
      harvests: {
        add: 'Ajouter une récolte',
        date: 'DATE DE RÉCOLTE',
        harvestType: 'MODE DE RÉCOLTE',
        culture: 'CULTURE',
        baleWeight: 'PAR BALLE (kg)',
        calculatedYield: 'TOTAL (kg)',
        hectares: 'SUPERFICIE (ha)',
        yieldPerHectare: 'RENDEMENT (kg/ha)',
        updatedAt: 'MODIFIÉ LE',
        open: 'Ouvrir',
        deletion: {
          button: 'Supprimer cette récolte',
          title: 'Supprimer?',
          text: 'Voulez-vous supprimer cette récolte?',
          confirm: 'Supprimer',
        },
        form: {
          title: 'Récolte',
          date: 'Date de la coupe',
          culture: 'CULTURE',
          cultureCategory: 'Catégorie de culture',
          cultureId: 'Culture',
          dimensions: 'DIMENSIONS',
          hectares: 'Superficie récoltée',
          harvestType: 'Mode de récolte',
          bales: 'Nombre de balles',
          moistureLevel: "Taux d'humidité (%)",
          comments: 'Commentaires',
          chamberType: 'Type de chambre',
          rotoCut: 'Système de récolte',
          widthFeet: 'Largeur (pi)',
          widthInches: 'Largeur (po)',
          diameterFeet: 'Diamètre (pi)',
          diameterInches: 'Diamètre (po)',
          lengthFeet: 'Longueur (pi)',
          lengthInches: 'Longueur (po)',
          heightFeet: 'Hauteur (pi)',
          heightInches: 'Hauteur (po)',
          siloNumber: 'Silo',
          startingHeight: 'Début (pi)',
          endingHeight: 'Fin (pi)',
          dailyFeed: 'Quantité par jour (kg)',
          days: 'Nombre de jours',
          loads: 'Nombre de voyages',
          estimation: "MESURES ESTIMÉES À 85% D'HUMIDITÉ",
          calculatedYield: 'Poids total',
          baleWeight: 'Poids par balle',
          yieldPerHectare: 'Rendement',
          towerSilo: {
            height: 'HAUTEUR',
            feed: 'PRÉLÈVEMENT',
          },
        },
        help: {
          comments: 'Les commentaires sont visibles par le producteur.',
        },
      },
      chamberTypes: {
        FIXED: 'Fixe',
        VARIABLE: 'Variable',
      },
      rotocutTypes: {
        NONE: 'Aucun',
        HALF: 'Demi roto-cut',
        FULL: 'Roto-cut complet',
      },
      notes: {
        placeholder: 'Ajouter une note',
        add: 'Ajouter',
      },
    },
    report: {
      title: 'Bilan de récolte de foin',
      season: 'Saison {{year}}',
      header: {
        clinum: 'Client : ',
        name: 'Nom du client : ',
        station: 'Station météo : ',
        print: 'Imprimer',
      },
      cultureLifecycle: {
        header: {
          perennial: 'Vivace',
          annual: 'Annuel',
          title: 'Bilan de récolte par fauche (85 % matière sèche)',
        },
      },
      mowing: {
        header: {
          mowing: 'Fauche {{number}}',
          start: 'Début',
          end: 'Fin',
          yield: 'Rendement (kg/ha)',
          hectares: 'Superficie totale récoltée en foin (ha)',
          harvestType: 'Mode de récolte',
          dimensions: 'Dimensions',
          units: 'Quantité',
          weight: "Poids de l'unité (kg)",
          totalWeight: 'Poids total (kg)',
        },
      },
      harvest: {
        SmallSquareBaleHarvest: '-',
        LargeMoistSquareBaleHarvest: '{{width}} (Largeur) x {{height}} (Hauteur) x {{length}} (Longueur)',
        LargeDrySquareBaleHarvest: '{{width}} (Largeur) x {{height}} (Hauteur) x {{length}} (Longueur)',
        MoistRoundBaleHarvest: '{{width}} (Largeur) x {{diameter}} (Diamètre)',
        DryRoundBaleHarvest: '{{width}} (Largeur) x {{diameter}} (Diamètre)',
        TowerSiloHarvest: 'Silo tour {{number}}: {{diameter}} x {{height}} pieds',
        BunkerSiloHarvest: '{{width}} (Largeur) x {{height}} (Hauteur) x {{length}} (Longueur)',
        SilagePileHarvest: '{{width}} (Largeur) x {{height}} (Hauteur) x {{length}} (Longueur)',
        BagHarvest: '{{length}} (Longueur) x {{diameter}} (Diamètre)',
        LoadHarvest: '{{width}} (Largeur) x {{height}} (Hauteur) x {{length}} (Longueur)',
      },
    },
  },
  participant: {
    list: {
      filters: {
        expectedMowing: 'Fauche prévue?',
        expectedMowingHelp: 'Afficher seulement les participants ayant prévu au moins {{selectedMowingNumber}} fauches',
      },
    },
    status: {
      select: {
        placeholder: 'Tous les statuts',
      },
      enum: {
        CALL: 'Appeler',
        RECALL1: 'Rappel 1',
        RECALL2: 'Rappel 2',
        RECALL3: 'Rappel 3',
        DONOTCALL: 'Ne pas rappeler',
        COMPLETED: 'Complétée',
      },
    },
    table: {
      headers: {
        number: 'CLINUM',
        appInstalled: 'APP.',
        farmName: 'NOM',
        regionType: 'RÉGION',
        expectedMowings: 'PRÉVUES',
        percentComplete: 'PROGRÈS',
        status: 'STATUT',
        updatedAt: 'MODIFIÉ LE',
        note: 'NOTE',
      },
      values: {
        hasUsers: {
          true: 'oui',
          false: 'non',
        },
        regionType: {
          regional: 'Périphérique',
          central: 'Centrale',
          unknown: '',
        },
      },
      pagination: {
        results: 'Participants {{lower}} à {{upper}} sur total de {{total}}',
        previous: 'Précédente',
        next: 'Suivante',
      },
    },
  },
  reports: {
    harvests: {
      title: 'Données de fauche',
      download: 'Télécharger le rapport',
    },
  },
  yieldAverage: {
    table: {
      headers: {
        serviceCenterNumber: '#',
        serviceCenterName: 'CENTRE DE SERVICES',
        referenceYield: 'RENDEMENT MOYEN kg/ha',
        averageYield: 'MOYENNE COLLECTO kg/ha',
        trend: 'TENDANCE',
        publishedYield: 'MOYENNE AFFICHÉE kg/ha',
        updatedAt: 'MODIFIÉ LE',
      },
    },
    actions: {
      cancel: 'Annuler',
      save: 'Sauvegarder',
    },
  },
};
