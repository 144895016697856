import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'chakra-react-select';

export type LanguageSelectProps = {
  onChange: (value: string | null | undefined) => void;
  value: string | null | undefined;
};

export const LanguageSelect: React.FC<LanguageSelectProps> = ({ onChange, value }) => {
  const { t } = useTranslation();
  const options = [
    { value: 'F', label: t('components.LanguageSelect.F') },
    { value: 'A', label: t('components.LanguageSelect.A') },
  ];

  const selectValue = options.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      value={selectValue}
      onChange={(e) => onChange(e?.value)}
      options={options}
      placeholder={t('components.select.placeholder')}
    />
  );
};
