import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from '@chakra-ui/react';

function getVariant(status: MowingStatusBadgeProps['status']) {
  switch (status) {
    case 'CALL':
      return 'participantCall';
    case 'RECALL1':
    case 'RECALL2':
    case 'RECALL3':
      return 'participantRecall';
    case 'DONOTCALL':
      return 'participantDoNotCall';
    case 'COMPLETED':
      return 'participantCompleted';
  }
}

export type MowingStatusBadgeProps = {
  status: 'CALL' | 'RECALL1' | 'RECALL2' | 'RECALL3' | 'DONOTCALL' | 'COMPLETED';
};

export const MowingStatusBadge: FC<MowingStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <Tag size='sm' verticalAlign='middle' variant={getVariant(status)}>
      {t(`participant.status.enum.${status}`)}
    </Tag>
  );
};
