import type * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { FormRow } from '../../Form';

import { eitherRequired } from './Validation';

export const LargeDrySquareBaleHarvestForm: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'enrollment.editor.harvests.form' });
  const { register, getValues } = useFormContext();

  return (
    <>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='largeDrySquareBaleHarvest.widthFeet'>{t('widthFeet')}</FormLabel>
          <Input
            id='largeDrySquareBaleHarvest.widthFeet'
            textAlign='right'
            {...register('largeDrySquareBaleHarvest.widthFeet', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('largeDrySquareBaleHarvest.widthFeet'),
                  getValues('largeDrySquareBaleHarvest.widthInches')
                ),
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='largeDrySquareBaleHarvest.widthInches'>{t('widthInches')}</FormLabel>
          <Input
            id='largeDrySquareBaleHarvest.widthInches'
            textAlign='right'
            {...register('largeDrySquareBaleHarvest.widthInches', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('largeDrySquareBaleHarvest.widthFeet'),
                  getValues('largeDrySquareBaleHarvest.widthInches')
                ),
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='largeDrySquareBaleHarvest.lengthFeet'>{t('lengthFeet')}</FormLabel>
          <Input
            id='largeDrySquareBaleHarvest.lengthFeet'
            textAlign='right'
            {...register('largeDrySquareBaleHarvest.lengthFeet', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('largeDrySquareBaleHarvest.lengthFeet'),
                  getValues('largeDrySquareBaleHarvest.lengthInches')
                ),
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='largeDrySquareBaleHarvest.lengthInches'>{t('lengthInches')}</FormLabel>
          <Input
            id='largeDrySquareBaleHarvest.lengthInches'
            textAlign='right'
            {...register('largeDrySquareBaleHarvest.lengthInches', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('largeDrySquareBaleHarvest.lengthFeet'),
                  getValues('largeDrySquareBaleHarvest.lengthInches')
                ),
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem 15rem'>
        <FormControl>
          <FormLabel htmlFor='largeDrySquareBaleHarvest.heightFeet'>{t('heightFeet')}</FormLabel>
          <Input
            id='largeDrySquareBaleHarvest.heightFeet'
            textAlign='right'
            {...register('largeDrySquareBaleHarvest.heightFeet', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('largeDrySquareBaleHarvest.heightFeet'),
                  getValues('largeDrySquareBaleHarvest.heightInches')
                ),
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='largeDrySquareBaleHarvest.heightInches'>{t('heightInches')}</FormLabel>
          <Input
            id='largeDrySquareBaleHarvest.heightInches'
            textAlign='right'
            {...register('largeDrySquareBaleHarvest.heightInches', {
              valueAsNumber: true,
              validate: () =>
                eitherRequired(
                  getValues('largeDrySquareBaleHarvest.heightFeet'),
                  getValues('largeDrySquareBaleHarvest.heightInches')
                ),
            })}
          />
        </FormControl>
      </FormRow>
      <FormRow templateColumns='15rem' spacing='0'>
        <FormLabel htmlFor='largeDrySquareBaleHarvest.bales'>{t('bales')}</FormLabel>
        <Input
          id='largeDrySquareBaleHarvest.bales'
          textAlign='right'
          {...register('largeDrySquareBaleHarvest.bales', { valueAsNumber: true, required: true })}
        />
      </FormRow>
    </>
  );
};
