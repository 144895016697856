import { type FC, useEffect, useMemo, useState } from 'react';
import { type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { type Column, useTable } from 'react-table';

import {
  Box,
  Button,
  Fade,
  Flex,
  HStack,
  Input,
  Progress,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import {
  type PublishedYieldAverageInput,
  useUpdatePublishedYieldAverageMutation,
  useYieldAverageQuery,
  type YieldAverageFragment,
} from '../../graphql/hooks';
import { Trend } from '../Trend';

type YieldAverageTableProps = {
  season: number | null;
  mowingNumber: number | null;
};

type PublishedYieldAverageForm = {
  values: Array<PublishedYieldAverageInput>;
};

export const YieldAverageTable: FC<YieldAverageTableProps> = ({ season, mowingNumber }) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'yieldAverage' });
  const dateTimeFormat = useMemo(() => new Intl.DateTimeFormat(i18n.language), [i18n.language]);

  const [loaded, setLoaded] = useState(false);

  const {
    control,
    register,
    reset,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm<PublishedYieldAverageForm>();
  const { fields } = useFieldArray({ name: 'values', control });

  const yieldAverageQueryKey = { seasonId: season || 0, mowingNumber: mowingNumber || 0 };

  const yieldAverageQuery = useYieldAverageQuery(yieldAverageQueryKey, {
    enabled: season !== null && mowingNumber !== null,
    refetchOnWindowFocus: false,
  });
  const yieldAverages = useMemo(() => yieldAverageQuery.data?.yieldAverage || [], [yieldAverageQuery]);

  useEffect(() => {
    if (yieldAverageQuery.isSuccess) {
      setLoaded(false);
    }
  }, [yieldAverageQuery.isSuccess]);

  useEffect(() => {
    if (yieldAverages.length > 0 && !loaded) {
      setLoaded(true);
      reset({
        values: yieldAverages.map((yieldAverage) => ({
          serviceCenterId: yieldAverage.serviceCenterId,
          publishedYield: yieldAverage.publishedYield,
        })),
      });
    }
  }, [loaded, reset, yieldAverages]);

  const queryClient = useQueryClient();
  const invalidate = async () => {
    await queryClient.invalidateQueries({ queryKey: ['yieldAverage', yieldAverageQueryKey] });
  };

  const updateMutation = useUpdatePublishedYieldAverageMutation({ onSuccess: invalidate });

  const onSubmit: SubmitHandler<PublishedYieldAverageForm> = async (data) =>
    updateMutation.mutate({
      seasonId: yieldAverageQueryKey.seasonId,
      mowingNumber: yieldAverageQueryKey.mowingNumber,
      data: data.values,
    });

  const defaultColumn: Partial<Column<YieldAverageFragment>> = useMemo(
    () => ({
      width: '0.1%',
    }),
    []
  );
  const columns: Array<Column<YieldAverageFragment>> = useMemo(
    () => [
      {
        accessor: 'serviceCenterNumber',
        Header: t('table.headers.serviceCenterNumber'),
      },
      {
        accessor: 'serviceCenterName',
        Header: t('table.headers.serviceCenterName'),
        width: '100%',
      },
      {
        accessor: 'referenceYield',
        Header: t('table.headers.referenceYield'),
      },
      {
        accessor: 'averageYield',
        Header: t('table.headers.averageYield'),
      },
      {
        accessor: 'trend',
        Header: t('table.headers.trend'),
        Cell: ({ value }: { value: number }) => <Trend value={value} />,
      },
      {
        accessor: 'publishedYield',
        Header: t('table.headers.publishedYield'),
        Cell: (cell) => {
          const field = fields[cell.row.index];
          // The react-table instance directly feeds on the query results
          // whereas the form feeds on a model that is initialized in a
          // useEffect, hence the null protection; it takes a render cycle for
          // the form fields to be initialized after the data is loaded by the
          // query; there is no visual glitch here, just a render cycle with nothing
          return field ? (
            <div key={field.id}>
              <Input type='hidden' {...register(`values.${cell.row.index}.serviceCenterId`)} />
              <Input {...register(`values.${cell.row.index}.publishedYield`, { valueAsNumber: true })} />
            </div>
          ) : (
            ''
          );
        },
      },
      {
        accessor: 'updatedAt',
        Header: t('table.headers.updatedAt'),
        Cell: ({ value }: { value: string }) => <span>{dateTimeFormat.format(new Date(value))}</span>,
      },
    ],
    [t, dateTimeFormat, fields, register]
  ) /* FIXME: Type Hack! */ as Array<Column<YieldAverageFragment>>;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    defaultColumn,
    data: yieldAverages,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        {yieldAverageQuery.isFetching ? (
          <Progress size='xs' colorScheme='green' isIndeterminate />
        ) : (
          <Spacer height={1} />
        )}
        <Stack spacing='5'>
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th {...column.getHeaderProps({ style: { width: column.width } })}>{column.render('Header')}</Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td
                        {...cell.getCellProps({ style: { width: cell.column.width, minWidth: cell.column.minWidth } })}
                      >
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Stack>
        <Fade in={isDirty}>
          <Box bottom='0' position='fixed' left='0' w='100%' py={10} px={14} bg='bg.surface'>
            <Flex>
              <Spacer />
              <HStack spacing={5}>
                <Button variant='tertiary' onClick={() => setLoaded(false)}>
                  {t('actions.cancel')}
                </Button>
                <Button variant='solid' colorScheme='blue' type='submit' isLoading={isSubmitting}>
                  {t('actions.save')}
                </Button>
              </HStack>
            </Flex>
          </Box>
        </Fade>
      </Box>
    </form>
  );
};
