import { createContext, type FC, type ReactNode, useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { getAuth, type User } from 'firebase/auth';

const FirebaseAuthContext = createContext<User | null | undefined>(undefined);

const FirebaseAuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const [user, setUser] = useState<User | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      queryClient.removeQueries();
      setUser(user);
      setInitialized(true);
    });
    return unsubscribe;
    // queryClient is always set, and never changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FirebaseAuthContext.Provider value={user}>{initialized && children}</FirebaseAuthContext.Provider>;
};

function useFirebaseAuth() {
  const user = useContext(FirebaseAuthContext);
  if (user === undefined) {
    throw new Error('useFirebaseAuth must be used within a FirebaseAuthProvider');
  }
  return user;
}

export { FirebaseAuthProvider, useFirebaseAuth };
