import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'chakra-react-select';

import { useLookups } from '../../contexts/LookupsContext';

export type CitySelectProps = {
  onChange: (cityId: number | null | undefined) => void;
  onBlur: () => void;
  value: number | null | undefined;
};

export const CitySelect: React.FC<CitySelectProps> = ({ onChange, onBlur, value }) => {
  const { t } = useTranslation();
  const { query, cities } = useLookups();

  const options = (cities.data || []).map((city) => ({ value: city.id, label: city.name }));
  const selectValue = options.find((option) => option.value === value);

  return query.isLoading ? (
    <span>{t('loading')}</span>
  ) : query.isError ? (
    <span>{t('error')}</span>
  ) : (
    <Select
      isSearchable
      value={selectValue}
      onChange={(e) => onChange(e?.value)}
      onBlur={onBlur}
      options={options}
      placeholder={t('components.select.placeholder')}
    />
  );
};
