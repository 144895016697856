import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { type OptionBase, Select } from 'chakra-react-select';

export type MowingNumberSelectProps = {
  onChange: (value: number) => void;
  value: number;
};

interface MowingNumberOption extends OptionBase {
  readonly value: number;
  readonly label: string;
}

export const MowingNumberSelect: React.FC<MowingNumberSelectProps> = ({ onChange, value }) => {
  const { t } = useTranslation();
  const mowingNumberOptions: readonly MowingNumberOption[] = Array.from({ length: 5 }, (_v, k) => ({
    value: k + 1,
    label: t('components.MowingNumberSelect.label', { number: k + 1 }),
  }));
  const selectedMowingNumberOption = mowingNumberOptions.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      isClearable={false}
      options={mowingNumberOptions}
      value={selectedMowingNumberOption}
      onChange={(e) => onChange(e?.value ?? 1)}
    />
  );
};
