import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'chakra-react-select';

import { MowingStatus } from '../../graphql/hooks';

export type MowingStatusSelectProps = {
  onChange: (value: MowingStatus | null) => void;
  value: MowingStatus | null | undefined;
  isClearable?: boolean;
};

const typedKeys = <T extends Record<string, unknown>>(object: T): (keyof T)[] => Object.keys(object) as (keyof T)[];

export const MowingStatusSelect: React.FC<MowingStatusSelectProps> = ({ onChange, value, isClearable }) => {
  const { t } = useTranslation();
  const options = typedKeys(MowingStatus).map((status) => ({
    value: MowingStatus[status],
    label: t(`participant.status.enum.${MowingStatus[status]}`),
  }));

  const selectValue = options.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      isClearable={isClearable}
      value={selectValue}
      onChange={(e) => onChange(e?.value ?? null)}
      options={options}
      placeholder={t('participant.status.select.placeholder')}
    />
  );
};
