import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Container, FormControl, Stack, StackDivider } from '@chakra-ui/react';

import { useFirebaseAuth } from '../contexts/FirebaseAuthContext';
import { useLookups } from '../contexts/LookupsContext';

import { FormHeader, FormRow } from './Form';
import { SeasonSelect } from './Season';
import { SponsorSelect } from './Sponsor';

export default function Reports() {
  const { t } = useTranslation();
  const { seasons } = useLookups();
  const user = useFirebaseAuth();

  const [selectedSeason, setSelectedSeason] = useState<number | null>(null);
  const [selectedSponsor, setSelectedSponsor] = useState<string | null>(null);

  useEffect(() => {
    if (selectedSeason === null && seasons.current) {
      setSelectedSeason(seasons.current.id);
    }
  }, [selectedSeason, seasons]);

  const download = async () => {
    if (user) {
      const token = await user.getIdToken();

      const link = document.createElement('a');
      link.href = `/api/report/harvests?seasonId=${selectedSeason}&sponsorId=${selectedSponsor}&auth=${token}`;
      link.click();
    }
  };

  return (
    <form>
      <Container px={{ base: 0, md: 10 }} mb='170px'>
        <Box bg='bg.surface' boxShadow='sm' borderRadius='lg' mb={5}>
          <Stack divider={<StackDivider />}>
            <FormHeader text={t('reports.harvests.title')} />
            <Stack mb={3}>
              <FormRow templateColumns='15rem 15rem 15rem'>
                <FormControl>
                  <SeasonSelect value={selectedSeason} onSelectSeason={setSelectedSeason} />
                </FormControl>
                <FormControl>
                  <SponsorSelect value={selectedSponsor} onSelectSponsor={setSelectedSponsor} />
                </FormControl>
                <FormControl>
                  <Button
                    variant='solid'
                    colorScheme='blue'
                    isDisabled={selectedSponsor === null || selectedSeason === null}
                    onClick={download}
                  >
                    {t('reports.harvests.download')}
                  </Button>
                </FormControl>
              </FormRow>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </form>
  );
}
