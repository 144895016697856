import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'chakra-react-select';

import { RegionType } from '../graphql/hooks';

export type RegionTypeSelectProps = {
  onChange: (value: RegionType | null) => void;
  value: RegionType | null;
};

export const RegionTypeSelect: React.FC<RegionTypeSelectProps> = ({ onChange, value }) => {
  const { t } = useTranslation();
  const options = [
    { value: RegionType.Central, label: t('components.RegionTypeSelect.values.central') },
    { value: RegionType.Regional, label: t('components.RegionTypeSelect.values.regional') },
  ];

  const selectValue = options.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      isClearable
      value={selectValue}
      onChange={(e) => onChange(e?.value ?? null)}
      options={options}
      placeholder={t('components.RegionTypeSelect.placeholder')}
    />
  );
};
