import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'chakra-react-select';

import { HarvestType } from '../../../graphql/hooks';

export type HarvestTypeSelectProps = {
  onChange: (value: HarvestType | null) => void;
  value: HarvestType | null | undefined;
  isClearable?: boolean;
  isDisabled?: boolean;
};

const typedKeys = <T extends Record<string, unknown>>(object: T): (keyof T)[] => Object.keys(object) as (keyof T)[];

export const HarvestTypeSelect: React.FC<HarvestTypeSelectProps> = ({ onChange, value, isClearable, isDisabled }) => {
  const { t } = useTranslation();
  const options = typedKeys(HarvestType)
    .map((harvestType) => ({
      value: HarvestType[harvestType],
      label: t(`harvestTypes.${HarvestType[harvestType]}`),
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));

  const selectValue = options.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      isClearable={isClearable}
      value={selectValue ?? null}
      onChange={(e) => onChange(e?.value ?? null)}
      options={options}
      placeholder={t('components.select.placeholder')}
      isDisabled={isDisabled}
    />
  );
};
