import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'chakra-react-select';

import { ChamberType } from '../../../graphql/hooks';

export type ChamberTypeSelectProps = {
  onChange: (value: ChamberType | null) => void;
  value: ChamberType | null | undefined;
  isClearable?: boolean;
};

const typedKeys = <T extends Record<string, unknown>>(object: T): (keyof T)[] => Object.keys(object) as (keyof T)[];

export const ChamberTypeSelect: React.FC<ChamberTypeSelectProps> = ({ onChange, value, isClearable }) => {
  const { t } = useTranslation();
  const options = typedKeys(ChamberType)
    .map((chamberType) => ({
      value: ChamberType[chamberType],
      label: t(`enrollment.editor.chamberTypes.${ChamberType[chamberType]}`),
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));

  const selectValue = options.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      isClearable={isClearable}
      value={selectValue ?? null}
      onChange={(e) => onChange(e?.value ?? null)}
      options={options}
      placeholder={t('components.select.placeholder')}
    />
  );
};
