export default {
  harvestTypes: {
    SmallSquareBaleHarvest: 'Small Square Bales',
    LargeMoistSquareBaleHarvest: 'Square Bales Silage',
    LargeDrySquareBaleHarvest: 'Dry Hay Square Bales',
    MoistRoundBaleHarvest: 'Round Bales Silage',
    DryRoundBaleHarvest: 'Dry Hay Round Bales',
    TowerSiloHarvest: 'Tower Silo',
    BunkerSiloHarvest: 'Bunker Silo',
    SilagePileHarvest: 'Silage Pile',
    BagHarvest: 'Sausage Bag',
    LoadHarvest: 'Load',
  },
  enrollment: {
    report: {
      title: 'Hay Harvest Report',
      season: 'Season {{year}}',
      header: {
        clinum: 'Client : ',
        name: 'Client Name : ',
        station: 'Meteo Station : ',
        print: 'Print',
      },
      cultureLifecycle: {
        header: {
          perennial: 'Perennial',
          annual: 'Annual',
          title: 'Harvest Balance by Mowing (85% dry matter)',
        },
      },
      mowing: {
        header: {
          mowing: 'Mowing {{number}}',
          start: 'Start',
          end: 'End',
          yield: 'Yield (kg/ha)',
          hectares: 'Total area harvested in hay (ha)',
          harvestType: 'Harvest Type',
          dimensions: 'Dimensions',
          units: 'Quantity',
          weight: 'Weight Per Unit (kg)',
          totalWeight: 'Total Weight (kg)',
        },
      },
      harvest: {
        SmallSquareBaleHarvest: '-',
        LargeMoistSquareBaleHarvest: '{{width}} (Width) x {{height}} (Height) x {{length}} (Length)',
        LargeDrySquareBaleHarvest: '{{width}} (Width) x {{height}} (Height) x {{length}} (Length)',
        MoistRoundBaleHarvest: '{{width}} (Width) x {{diameter}} (Diameter)',
        DryRoundBaleHarvest: '{{width}} (Width) x {{diameter}} (Diameter)',
        TowerSiloHarvest: 'Tower Silo {{number}}: {{diameter}} x {{height}} feet',
        BunkerSiloHarvest: '{{width}} (Width) x {{height}} (Height) x {{length}} (Length)',
        SilagePileHarvest: '{{width}} (Width) x {{height}} (Height) x {{length}} (Length)',
        BagHarvest: '{{length}} (Length) x {{diameter}} (Diameter)',
        LoadHarvest: '{{width}} (Width) x {{height}} (Height) x {{length}} (Length)',
      },
    },
  },
};
