import type * as React from 'react';
import { useEffect } from 'react';

import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSessionstorageState } from 'rooks';
import { useDebounce } from 'use-debounce';

import { useLookups } from '../../contexts/LookupsContext';
import type { EnrollmentStatus } from '../../graphql/hooks';
import { SeasonSelect } from '../Season';
import { StationSelect } from '../Station';

import { EnrollmentStatusSelect } from './EnrollmentStatusSelect';
import { EnrollmentTable } from './EnrollmentTable';

export const EnrollmentListPage: React.FC = () => {
  const { seasons } = useLookups();

  const [selectedSeason, setSelectedSeason] = useSessionstorageState<number | null>('enrollments.selectedSeason', null);
  const [selectedStation, setSelectedStation] = useSessionstorageState<number | null>(
    'enrollments.selectedStation',
    null
  );
  const [selectedStatus, setSelectedStatus] = useSessionstorageState<EnrollmentStatus | null>(
    'enrollments.selectedStatus',
    null
  );
  const [searchText, setSearchText] = useSessionstorageState<string>('enrollments.searchText', '');
  const [search] = useDebounce(searchText, 500);

  useEffect(() => {
    if (selectedSeason === null && seasons.current) {
      setSelectedSeason(seasons.current.id);
    }
  }, [setSelectedSeason, selectedSeason, seasons]);

  return (
    <Container px={{ base: 0, md: 3 }}>
      <Box
        bg='bg.surface'
        boxShadow={{ base: 'none', md: useColorModeValue('sm', 'sm-dark') }}
        borderRadius={useBreakpointValue({ base: 'none', md: 'lg' })}
        mb={{ base: 3, md: 16 }}
      >
        <Stack spacing={5}>
          <Box px={{ base: 4, md: 6 }} pt={5}>
            <SimpleGrid
              spacing={{ sm: 5, xl: 10 }}
              templateColumns={{ sm: '1fr', md: '1fr 1fr', xl: '12rem 32rem 16rem 1fr' }}
            >
              <SeasonSelect value={selectedSeason} onSelectSeason={setSelectedSeason} />
              <StationSelect
                isClearable
                season={selectedSeason}
                value={selectedStation}
                onSelectStation={setSelectedStation}
              />
              <EnrollmentStatusSelect isClearable value={selectedStatus} onChange={setSelectedStatus} />
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <SearchIcon color='gray.300' />
                </InputLeftElement>
                <Input onChange={(e) => setSearchText(e.target.value)} value={searchText} />
              </InputGroup>
            </SimpleGrid>
          </Box>
          <Box overflowX='auto'>
            <EnrollmentTable
              season={selectedSeason}
              station={selectedStation}
              status={selectedStatus}
              search={search}
            />
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
