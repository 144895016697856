import type * as React from 'react';
import { useEffect, useState } from 'react';

import { Box, Container, SimpleGrid, Stack, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

import { useLookups } from '../../contexts/LookupsContext';
import { MowingNumberSelect } from '../MowingNumberSelect';
import { SeasonSelect } from '../Season';

import { YieldAverageTable } from './YieldAverageTable';

export const YieldAverageListPage: React.FC = () => {
  const { seasons } = useLookups();
  const [selectedSeason, setSelectedSeason] = useState<number | null>(null);
  const [selectedMowingNumber, setSelectedMowingNumber] = useState<number>(1);

  useEffect(() => {
    if (selectedSeason === null && seasons.current) {
      setSelectedSeason(seasons.current.id);
    }
  }, [selectedSeason, seasons]);

  return (
    <Container px={{ base: 0, md: 3 }} maxW='8xl'>
      <Box
        bg='bg.surface'
        boxShadow={{ base: 'none', md: useColorModeValue('sm', 'sm-dark') }}
        borderRadius={useBreakpointValue({ base: 'none', md: 'lg' })}
        mb={{ base: 3, md: 16 }}
      >
        <Stack spacing={5}>
          <Box px={{ base: 4, md: 6 }} pt={5}>
            <SimpleGrid spacing={{ sm: 5, xl: 10 }} templateColumns={{ sm: '1fr', md: '1fr 1fr', xl: '12rem 12rem' }}>
              <SeasonSelect value={selectedSeason} onSelectSeason={setSelectedSeason} />
              <MowingNumberSelect value={selectedMowingNumber} onChange={setSelectedMowingNumber} />
            </SimpleGrid>
          </Box>
          <Box overflowX='auto'>
            <YieldAverageTable season={selectedSeason} mowingNumber={selectedMowingNumber} />
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
